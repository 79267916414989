import React, {
  useEffect,
  useState,
  Fragment,
  useRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from "react";

import "./CreateJob.scss";
import useStyles from "./CreateJobStyle";
import CreateJobEndpoints from "./CreateJob.endpoints";
import CommonEndpoints from "../../services/commonApi.service";
import crossIcon from "../../assets/images/crossIcon.svg";
import createJobService from "./services/CreateJob.service";
import AutocompleteMaterial from "@material-ui/lab/Autocomplete";

import {
  Modal,
  Backdrop,
  Fade,
  Switch,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Paper,
  TextField,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CancelIcon from "@material-ui/icons/Cancel";
import MapOutlinedIcon from "@material-ui/icons/MapOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { MuiPickersUtilsProvider, validate } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment-timezone";
import AddIcon from "@material-ui/icons/Add";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "../Autocomplete/Autocomplete";
import CreateJobMap from "../CreateJobMap/CreateJobMap";
import CreateRecurringJob from "./CreateRecurringJob";
import Input from "../Input/Input";
import Phone from "../phone-picker/phone-picker";
import { useLocation } from "react-router";
import Dropdown from "../Dropdown/Dropdown";
import MaterialAutocomplete from "../MaterialAutocomplete/MaterialAutocomplete";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Btn from "../Button/Button";
import { useAppContext } from "../../AppContext/App.context";
import useToast from "../../components/Toast/hooks/useToast";
import useLoader from "../../hooks/useLoader";
import useApiService from "../../services/api.service";
import { templateApiService } from "../../services/ApiServices/templateApi.service";
import ResourceListDropdown from "../ResourceListDropdown/ResourceListDropdown";
import TemplateFieldsInputForm from "../TemplatesCommonMethods/TemplateFieldsInputForm";
import templateMethodService from "../TemplatesCommonMethods/TemplateMethod.service";
import { ValidationService } from "../../utils/Validation";
import { CreateTaskOperation } from "../../enum/CreateTaskOperation.enum";
import headerDropdownService from "../../services/headerDropdown.service";
import DateTimeFormatter from "../../utils/DateFormatter";
import useLocaStorage from "../../hooks/localStorage";
import { useUpdateOnboardStep } from "../../services/useUpdateOnboardStep";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import useLocalStorage from "../../utils/localStorage";
import DateTimePicker from "../DateTimePicker/DateTimePicker";
import { themeColor } from "../../constants/themeColor";
import { extensionList } from "../../constants/extensionList";
import Tooltip from "../Tooltip/Tooltip";
import CreateJobInfo from "../../containers/Dashboard/OnboardingCreateJob/CreateJobInfo";
import * as actions from "../../AppContext/actions/actions";
import { FastfoodOutlined } from "@material-ui/icons";
import ExtensionsList from "../../containers/Extensions/ExtensionList";
import { extensionService } from "../../containers/Extensions/Extensions.endpoints";
import { TemplateDataTypeEnum } from "../../enum/Template.enum";
import useDebounce from "../Datatable/use-debounce";
import RecurringTaskEndpoints from "../../containers/Settings/Recurring/RecurringTask.endpoints";

let totalCapacity: any = 0;
const CreateJob = forwardRef((props: any, ref: any) => {
  const { t, i18n } = useTranslation([
    "Common",
    "Createjob",
    "Validation",
    "ImportJobs",
  ]);
  const [latInputShrink, setLatInputShrink]: any = useState(false);
  const [LngInputShrink, setLngInputShrink]: any = useState(false);
  const toaster = useToast();
  const classes = useStyles();
  const localStorageObject = useLocaStorage;
  const loginData: any = useLocalStorage.getItem("loginData");
  const [workflow, setWorkflow]: any = useState(
    loginData?.workflow_type && loginData?.workflow_type < 2
      ? loginData?.workflow_type
      : 0
  );
  const [open, setOpen] = React.useState(false);
  const [expanded, setExpanded]: any = useState("accordion00");
  const [showMap, setShowMap] = useState(false);
  const Permission: any = localStorageObject.getItem("Permission");
  const handleChange = (panel: any) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [isRecurringEdit, setIsRecurringEdit]: any = useState(false);
  const [recurringId, setRecurringId]: any = useState();
  const [searchTerm, setSearchTerm]: any = useState({ term: "" });
  const debouncedSearchTerm = useDebounce(searchTerm.term, 300);

  const [nameIndex, setNameIndex]: any = useState();
  const [namePickupList, setPickupAutocompleteNameList]: any = useState([]);
  const [nameDeliveryList, setDeliveryAutocompleteNameList]: any = useState([]);
  const [dateHelperText, setDateHelperText] = useState("");
  const [locationHelperText, setLocationHelperText]: any = useState("");
  const [mapList, setMapList]: any = useState({});
  const newMinuteInDateForNewJob = new Date(
    new Date().setMinutes(new Date().getMinutes() + 20)
  );
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [selectedDate, handleDateChange] = useState(newMinuteInDateForNewJob);
  const [markerAddress, setAutocompleteMarkerAddress]: any = useState();
  const [unit, setUnit]: any = useState();
  const [autocompleteResponse, setAutocompleteResponse]: any = useState([]);
  const [selectedOption, setSelectedOption]: any = useState();
  const scrollRefs: any = useMemo(
    () =>
      Array(10)
        .fill(0)
        .map((i) => React.createRef()),
    []
  );

  const nameAutocomplete: any = useRef();
  const [dataForResourceFiltering, setDataForResourceFiltering]: any = useState(
    {}
  );
  const activatedExtension = localStorage.getItem("activatedExtension");
  const createJobMapRef: any = useRef();
  const setAutocomplete: any = useRef();
  let [activeTab, setActiveTab]: any = useState(0);
  const [selectedTeamId, setTeamId]: any = useState({});
  const [preSavedTeamForEdit, setPreSavedTeamForEdit] = useState(0);
  const [teamList, setTeamList]: any = useState([]);
  const [showLocation, setshowLocation]: any = useState(false);
  const [barcode, setShowBarcode]: any = useState(false);
  const [templateList, setTemplateList]: any = useState([]);
  const [
    stateForReRenderTemplateFieldList,
    updateStateForReRenderTemplateFieldList,
  ] = useState(Math.random());

  const [assignmentData, setAssignmentData]: any = useState({});
  const [isAutoAllocation, setIsAutoAllocation] = useState(false);

  const [selectedTags, updateSelectedTags]: any = useState([]);
  let [preSavedTagList, updatePreSavedTagList]: any = useState([]);
  const [operation, setOperation]: any = useState(0);

  const [selectedGeofence, setGeofence] = useState(0);
  const [selectedResourceId, updateSelectedResource]: any = useState();
  const ApiService = useApiService();
  const loader: any = useLoader();
  const [appData, dispatch]: any = useAppContext();
  const access_token = appData.loginData.access_token;
  const userId = appData.loginData.user_id;
  const [jobDataForEditTask, setJobDataForEditTask]: any = useState({});
  const [showServiceTime, setShowServiceTime]: any = useState(
    appData.loginData.allow_service_time
      ? appData.loginData.allow_service_time
      : false
  );

  const geoFenceOptionList = [
    { label: t("Createjob:Yes"), value: 1 },
    { label: t("Createjob:No"), value: 0 },
  ];
  const [repeatObj, updateRepeatObj] = useState({
    cycle: 0,
    cycle_def: {
      0: true,
      1: true,
      2: true,
      3: true,
      4: true,
      5: true,
      6: true,
    },
    endDate: "",
    startOn: "",
    recurringName: "",
    occurrences: "",
    scheduleEndCriteria: "0",
    isRecurringEnabled: false,
  });

  const handleClose = () => {
    if (
      appData.loginData.onboard_step === 7 ||
      !appData.loginData.onboard_step
    ) {
      setOpen(false);
      // resetCreateTaskForm();
      resetData();
      setPickupAutocompleteNameList([]);
      setDeliveryAutocompleteNameList([]);
    }
  };
  const resetData = () => {
    setActiveTab(0);
    setshowLocation(false);
    setLatInputShrink(false);
    setLngInputShrink(false);
    setShowMap(false);
    setMapList({});
    if (workflow === 1) {
      setExpanded(`accordion01`);
      // addTaskToJob(1)
    } else {
      setExpanded(`accordion00`);
    }
  };

  const obj = {
    datetime: {
      required: ValidationService.requiredValidator(),
      validate: ValidationService.dateTimeValidatorMoreThanCurrentTime,
    },
    password: {
      required: ValidationService.requiredValidator(),
    },
    name: {
      required: ValidationService.requiredValidator(),
    },
    phone: {
      // required: ValidationService.requiredValidator(),
    },
    barcode: {},
    email: {
      required: ValidationService.requiredValidator(),
      pattern: ValidationService.emailValidator(),
    },
  };

  useImperativeHandle(ref, () => ({
    handleOpen: (
      operation: number,
      jobId?: any,
      isComeFromRecurringEdit?: any
    ) => {
      resetCreateTaskForm();

      setOperation(operation);
      switch (operation) {
        case 1:
        case 2:
          if (isComeFromRecurringEdit) {
            setRecurringId(jobId.row.id);
            setIsRecurringEdit(true);
            openEditDuplicateTask(operation, jobId, isComeFromRecurringEdit);
          } else {
            if (jobId)
              openEditDuplicateTask(operation, jobId, isComeFromRecurringEdit);
          }

          //getJobDetailsByJobId(jobId);
          break;
        default:
          if (workflow === 1) {
            addTaskToJob(1);
          }
          if (workflow === 0 || workflow === 2) {
            // setTimeout(() => {
            addTaskToJob(0);
            // }, 300);
            setTimeout(() => {
              addTaskToJob(1);
            }, 100);
          }

          getTemplateList();

          setOpen(true);
          if (
            appData.loginData.onboard_step < 6 &&
            appData.loginData.onboard_step
          ) {
            setShowInfoPopup(true);
          } else {
            setShowInfoPopup(false);
          }
          getAssignment(0);
          break;
      }

      //setOpen(true);
    },
  }));

  const openEditDuplicateTask = async (
    operation: number,
    jobId: any,
    isComeFromRecurringEdit?: any
  ) => {
    // const
    if (!isComeFromRecurringEdit) {
      let jobData: any = await getJobDetailsByJobId(jobId);
      const templateList = await getTemplateList();
      setJobDataInForm(
        operation,
        jobData.data.data,
        {
          templateList: templateList,
        },
        null
      );
    } else {
      const templateList = await getTemplateList();
      setJobDataInForm(
        operation,
        null,
        {
          templateList: templateList,
        },
        jobId
      );
      setIsAutoAllocation(jobId.row.definition.task_body.auto_assign);
    }
    if (isComeFromRecurringEdit) {
      // const res = await getRecurringDetails(jobData.data.data[0].recurring_id);
      // console.log(res, "response wwe get");
      let cycleDef: any;
      if (jobId?.row?.definition?.cycle == 1) {
        cycleDef = jobId.row.definition.cycle_def;
      } else {
        cycleDef = repeatObj.cycle_def;
      }

      updateRepeatObj({
        cycle: jobId.row.definition.cycle,
        cycle_def: cycleDef,
        // cycle_def: jobId.row.definition.cycle_def,
        endDate: jobId.row.end_date,
        startOn: jobId.row.start_date,
        occurrences: jobId.row.definition.occurrences,
        recurringName: jobId.row.name,
        scheduleEndCriteria: jobId.row.definition.occurrences == 0 ? "0" : "1",
        isRecurringEnabled: true,
      });
    }
    loader.hideLoader();
    setOpen(true);
  };
  const getJobDetailsByJobId = async (jobId: any) => {
    let headers = {
      access_token: access_token,
    };
    let qureyParams = {
      skip: 0,
      limit: 1,
      job_id: jobId,
      fetch_task_details: true,
      fetch_resource_details: true,
      fetch_tags_details: true,
      fetch_team_details: true,
      fetch_template_details: true,
    };
    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    const res: any = await ApiService.get(
      CommonEndpoints.getJobDetailsByJobId(reqData)
    );
    return res;
  };
  const splitPhone = (data: any) => {
    if (data[0] === "+") {
      data = data.substring(1, data.length);
    }
    return data;
    // if (data.length < 14) {
    //   return data;
    // } else {
    //   let z = data.length;
    //   console.log(data.substring(z - 12, z));
    //   return data.substring(z - 12, z);
    // }
  };
  const getTempalteByTemplateId = (selectedTemplateId: number, list: any) => {
    const totalTemplate = list.length;
    for (let i = 0; i < totalTemplate; i++) {
      if (list[i]["template_id"] === selectedTemplateId) {
        return list[i];
      }
    }
  };

  const setJobDataInForm = (
    operation: number,
    jobData: any,
    additionalData: any,
    recurringData: any
  ) => {
    // if(recurringData)
    let is_pickup: any = 0;
    let is_delivery: any = 0;

    if (recurringData) {
      jobData = [
        {
          tasks: [],
        },
      ];
      jobData[0] = recurringData.row.definition.task_body;
      // jobData[0].tasks.push(
      //   recurringData.row.definition.task_body.task_details
      // );
    }

    if (operation === CreateTaskOperation.DUPLICATE || isRecurringEdit) {
      getAssignment(jobData[0]?.team_id || 0);
    }
    let lengthOfArray = recurringData
      ? jobData[0]["task_details"]?.length
      : jobData[0]["tasks"]?.length;

    reset({ barcode: jobData[0]["barcode"] || "" });
    setJobDataForEditTask(jobData[0]);
    for (let i = 0; i < lengthOfArray; i++) {
      let task: any;
      if (recurringData) {
        task = jobData[0]["task_details"][i];
      } else {
        task = jobData[0]["tasks"][i];
      }
      const phone = splitPhone(task.country_code + task.phone);
      let capacityData;
      if (task.task_type == 0) {
        capacityData = task.capacity;
        totalCapacity = totalCapacity + task.capacity;
      } else {
        capacityData = -task.capacity;
      }
      let taskData: any = {
        taskDescription: task.task_description,
        task_type: task.task_type,
        referenceId: task.reference_id,
        local_datetime: null,
        local_enddatetime: null,
        location: task.location,
        lat: task.lat,
        lng: task.lng,
        country_code: task.country_code,
        phone: task.phone,
        defaultPhone: phone,
        email: task.email,
        name: task.name,
        capacity: capacityData,
        template: null,
        templateFieldList: [],
        task_id: task.task_id,
        job_id: jobData[0]?.job_id,
      };
      if (showServiceTime) {
        taskData["service_time"] = task.service_time;
      }
      if (task.template && task.template.template_id) {
        taskData["template"] = getTempalteByTemplateId(
          task.template.template_id,
          additionalData.templateList
        );
        taskData[
          "templateFieldList"
        ] = templateMethodService.parseTemplateDataForInputForm(
          task.template.data
        );
      }

      if (operation === CreateTaskOperation.EDIT) {
        taskData[
          "local_datetime"
        ] = DateTimeFormatter.converDateTimeTo24HoursWithoutUTC(
          task.local_datetime
        );
      }
      if (task.task_type === 2) {
        setWorkflow(1);
        useLocalStorage.setItem("workflow", 1);
        if (operation === CreateTaskOperation.EDIT) {
          taskData[
            "local_enddatetime"
          ] = DateTimeFormatter.converDateTimeTo24HoursWithoutUTC(
            task.end_datetime
          );
        }
      } else {
        setWorkflow(0);
        useLocalStorage.setItem("workflow", 0);
      }

      let accordionId = "";
      if (task.task_type === 0) {
        pickupAppend(taskData);
        is_pickup = 1;
        accordionId = "accordion" + pickupFields.length + task.task_type;
      } else {
        deliveryAppend(taskData);
        is_delivery = 1;
        accordionId = "accordion" + deliveryFields.length + task.task_type;
      }
      if (i === 0) {
        openAccordion(accordionId);
      }
    }
    if (operation === CreateTaskOperation.EDIT) {
      if (pickupFields.length > 0 || is_pickup) {
        setActiveTab(0);
        setExpanded(`accordion00`);
      }
      if ((deliveryFields.length > 0 || !is_pickup) && workflow != 1) {
        setActiveTab(1);
      }
    }
    if (operation === CreateTaskOperation.EDIT && workflow === 1) {
      setActiveTab(0);
    }
    if (workflow === 1) {
      setExpanded(`accordion01`);
    }
    if (operation === CreateTaskOperation.DUPLICATE) {
      if (workflow === 0) {
        if (is_pickup) {
          setActiveTab(0);
          setExpanded(`accordion00`);

          if (!is_delivery) {
            addTaskToJob(1);
          }
        } else {
          setActiveTab(1);
          if (!is_pickup) {
            addTaskToJob(0);
          }
        }
      }
    }

    setPrefillDataForAssignResourcePart(jobData[0]);
  };
  const findTeamByTeamId = (teamId: any, teamList: any) => {
    const totalTeams = teamList.length;
    for (let i = 0; i < totalTeams; i++) {
      if (teamList[i]["team_id"] === teamId) {
        return teamList[i];
      }
    }
    return null;
  };
  const setPrefillDataForAssignResourcePart = async (jobData: any) => {
    updatePropsForFilteringTeam(jobData.use_geofence_check ? 1 : 0, jobData);
    // setTeamId(jobData.team_id);
    const arr = teamList;
    setPreSavedTeamForEdit(jobData.team_id);
    const teamObj = findTeamByTeamId(jobData.team_id, arr);
    // if (operation === CreateTaskOperation.DUPLICATE) {
    //   getAssignment(teamObj?.team_id || 0);
    // }
    setTeamId(teamObj, arr);
    getTagList(jobData.team_id);
    setGeofence(jobData.use_geofence_check ? 1 : 0);
    updateSelectedTags(jobData.tags || []);
    updateSelectedResource(jobData.resource_id);
    updatePropsForFilteringResource({
      type: "all",
      resourceId: jobData.resource_id,
      tags: jobData.tags || [],
      teamId: jobData.team_id,
      geofence: jobData.use_geofence_check ? 1 : 0,
    });
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    getValues,
    errors,
    setValue,
  } = useForm();
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "pickups",
    }
  );
  const {
    fields: pickupFields,
    append: pickupAppend,
    remove: pickupRemove,
  } = useFieldArray({ control, name: "pickup" });
  const {
    fields: deliveryFields,
    append: deliveryAppend,
    remove: deliveryRemove,
  } = useFieldArray({ control, name: "delivery" });

  const openAccordion = (accordionId: string) => {
    setExpanded(accordionId);
  };
  const isLatitude = (num: any) => isFinite(num) && Math.abs(num) <= 90;
  const isLongitude = (num: any) => isFinite(num) && Math.abs(num) <= 180;

  function getNumbersAsOneValue(str: any) {
    if (!str) {
      return "";
    }
    return str.replace(/\D/g, "");
  }
  //-------------------------------- Submit of Create Job -------------------------------------------
  const onSubmit = (data: any) => {
    let taskDetails: any = [];
    let taskDatesForSchedulingRule: any = [];
    for (let i = 0; i < pickupFields.length; i++) {
      if (pickupFields[i].phone) {
        const taskLocation = {
          lat: pickupFields[i].lat,
          lng: pickupFields[i].lng,
        };
        pickupFields[i].timezone = createJobService.getTimezoneForTask(
          pickupFields[i].local_datetime,
          taskLocation
        );
        pickupFields[i].timezone_string = createJobService.getTimezoneForTask(
          pickupFields[i].local_datetime,
          taskLocation,
          "timezonestring"
        );
        let pickupData: any = {
          task_description:
            data["task"]?.[0][i].description || pickupFields[i].taskDescription,
          task_type: 0,
          reference_id:
            data["task"]?.[0][i].referenceId || pickupFields[i].referenceId,
          name: data["task"]?.[0][i].name || pickupFields[i].name?.trim(),
          email: data["task"]?.[0][i].email || pickupFields[i].email,
          local_datetime: pickupFields[i].local_datetime,
          timezone: pickupFields[i].timezone,
          timezone_string: pickupFields[i].timezone_string,
          location: pickupFields[i].location,
          lat: parseFloat(pickupFields[i].lat),
          lng: parseFloat(pickupFields[i].lng),
          country_code: pickupFields[i].dialCode,
          phone:
            parseInt(getNumbersAsOneValue(data["task"]?.[0][i].phone)) ||
            Number(getNumbersAsOneValue(pickupFields[i].phone)),
        };
        if (activatedExtension?.includes(extensionList.capacityManagement.id)) {
          pickupData["capacity"] =
            Number(data["task"]?.[0][i].capacity) ||
            Number(pickupFields[i].capacity);
        }
        if (showServiceTime) {
          let serviceTime =
            data["task"]?.[0][i].service_time || pickupFields[i].service_time;
          if (serviceTime && Number(serviceTime) > 0) {
            pickupData["service_time"] = Number(serviceTime);
          }
        }
        if (pickupFields[i].template && pickupFields[i].template.template_id) {
          pickupData["template"] = {
            template_id: pickupFields[i].template.template_id,
            data: pickupFields[i].templateFieldList,
          };
        } else {
          pickupData["template"] = {};
        }
        taskDetails.push(pickupData);
        if (operation === CreateTaskOperation.EDIT) {
          taskDetails[taskDetails.length - 1]["task_id"] =
            pickupFields[i]["task_id"];
        }
        taskDatesForSchedulingRule.push({
          local_datetime: pickupFields[i].local_datetime,
          timezone: pickupFields[i].timezone_string,
        });
      }
    }
    for (let i = 0; i < deliveryFields.length; i++) {
      if (deliveryFields[i].phone) {
        const taskLocation = {
          lat: deliveryFields[i].lat,
          lng: deliveryFields[i].lng,
        };

        deliveryFields[i].timezone = createJobService.getTimezoneForTask(
          deliveryFields[i].local_datetime,
          taskLocation
        );
        deliveryFields[i].timezone_string = createJobService.getTimezoneForTask(
          deliveryFields[i].local_datetime,
          taskLocation,
          "timezonestring"
        );
        let deliveryData: any = {
          task_description:
            data["task"]?.[1][i].description ||
            deliveryFields[i].taskDescription,
          name: data["task"]?.[1][i].name || deliveryFields[i].name?.trim(),
          email: data["task"]?.[1][i].email || deliveryFields[i].email,
          // task_type: 1,
          reference_id:
            data["task"]?.[1][i].referenceId || deliveryFields[i].referenceId,
          // local_datetime: deliveryFields[i].local_datetime,
          timezone: deliveryFields[i].timezone,
          timezone_string: deliveryFields[i].timezone_string,
          location: deliveryFields[i].location,
          lat: parseFloat(deliveryFields[i].lat),
          lng: parseFloat(deliveryFields[i].lng),
          country_code:
            deliveryFields[i].dialCode || deliveryFields[i].country_code,
          phone:
            parseInt(getNumbersAsOneValue(data["task"]?.[1][i].phone)) ||
            parseInt(getNumbersAsOneValue(deliveryFields[i].phone)),
        };
        if (activatedExtension?.includes(extensionList.capacityManagement.id)) {
          deliveryData["capacity"] =
            Number(-data["task"]?.[0][i].capacity) ||
            Number(-deliveryFields[i].capacity);
        }
        if (workflow === 1) {
          deliveryData["end_datetime"] = deliveryFields[i].local_enddatetime;
          deliveryData["start_datetime"] = deliveryFields[i].local_datetime;
          deliveryData["task_type"] = 2;
        } else {
          deliveryData["local_datetime"] = deliveryFields[i].local_datetime;
          deliveryData["task_type"] = 1;
          if (showServiceTime) {
            let serviceTime =
              data["task"]?.[1][i].service_time ||
              deliveryFields[i].service_time;
            if (serviceTime && Number(serviceTime) > 0) {
              deliveryData["service_time"] = Number(serviceTime);
            }
          }
        }
        if (
          deliveryFields[i].template &&
          deliveryFields[i].template.template_id
        ) {
          deliveryData["template"] = {
            template_id: deliveryFields[i].template.template_id,
            data: deliveryFields[i].templateFieldList,
          };
        }
        taskDetails.push(deliveryData);
        if (operation === CreateTaskOperation.EDIT) {
          taskDetails[taskDetails.length - 1]["task_id"] =
            deliveryFields[i]["task_id"];
        }
        taskDatesForSchedulingRule.push({
          local_datetime: deliveryFields[i].local_datetime,
          timezone: deliveryFields[i].timezone_string,
        });
      }
    }

    let reqBody: any = {
      task_details: taskDetails,
      tags: [],
      workflow_type: workflow,
    };
    if (selectedTeamId && selectedTeamId.team_id) {
      reqBody["team_id"] = selectedTeamId.team_id;
    } else if (
      operation === CreateTaskOperation.EDIT &&
      preSavedTeamForEdit &&
      (!selectedTeamId || !selectedTeamId.team_id)
    ) {
      if (Permission && !Permission["Read_Teams"]) {
        reqBody["team_id"] = preSavedTeamForEdit;
      } else {
        toaster.addToast({
          message: t("Common:Please select a team"),
          timeout: 3000,
          type: "error",
        });
        return;
      }
    }
    if (
      selectedGeofence &&
      (!selectedTeamId || !Object.keys(selectedTeamId).length)
    ) {
      toaster.addToast({
        message: t("Common:Please select a team"),
        timeout: 3000,
        type: "error",
      });
      return;
    }
    if (selectedGeofence && !teamList.length) {
      toaster.addToast({
        message: t("Createjob:No geofence created for this location."),
        timeout: 3000,
        type: "error",
      });
      return;
    }

    reqBody["use_geofence_check"] = selectedGeofence;
    if (isAutoAllocation && operation !== CreateTaskOperation.EDIT) {
      reqBody["auto_assign"] = isAutoAllocation;
    }
    if (data.barcode) {
      reqBody["barcode"] = data.barcode;
    }

    // if (selectedGeofence) {
    //   reqBody["lat"] = taskDetails[0]["lat"];
    //   reqBody["lng"] = taskDetails[0]["lng"];
    // }
    if (reqBody["auto_assign"] !== true) {
      if (selectedResourceId) {
        reqBody["resource_id"] = selectedResourceId;
      } else if (operation === CreateTaskOperation.EDIT) {
        reqBody["resource_id"] = 0;
        reqBody["job_status"] = 0;
        // reqBody['job_status'] = 0;
      }
    }

    if (selectedTags.length > 0) {
      const tempTags = selectedTags.map((tag: any) => {
        if (typeof tag === "string") {
          return tag;
        }
        return tag.tag_name;
      });
      reqBody["tags"] = tempTags;
    }
    if (operation === CreateTaskOperation.EDIT) {
      reqBody["action_type"] = 0;
      if (pickupFields && pickupFields[0]) {
        reqBody["job_id"] = pickupFields[0]["job_id"];
      } else {
        reqBody["job_id"] = deliveryFields[0]["job_id"];
      }
    }

    let reqBodyWithRecurring = { ...reqBody };
    if (repeatObj.isRecurringEnabled) {
      const startAndEndTaskDateTimezone = createJobService.getStartAndEndTaskDateTimezone(
        taskDatesForSchedulingRule
      );

      if (isRecurringEdit) {
        Object.assign(repeatObj, { id: recurringId });
        if (selectedResourceId) {
          reqBody["auto_assign"] = false;
        } else {
          reqBody["auto_assign"] = isAutoAllocation;
        }
      }

      const updateRdecurringObj = {
        ...repeatObj,
        ...startAndEndTaskDateTimezone,
      };
      const error = createJobService.validateRecurringData(updateRdecurringObj);
      if (error) {
        setExpanded("accordion-recurring");
        toaster.addToast({
          message: t(`Createjob:${error}`),
          timeout: 3000,
          type: "error",
        });
        return;
      }
      const recurringBody: any = createJobService.getRecurringBody(
        updateRdecurringObj,
        isRecurringEdit
      );
      reqBodyWithRecurring = {
        ...recurringBody,
        task_body: { ...reqBody },
      };
      if (reqBody.resource_id) {
        reqBodyWithRecurring["resource_id"] = reqBody.resource_id;
      }
      if (reqBody.team_id) {
        reqBodyWithRecurring["team_id"] = reqBody.team_id;
      }
    }
    const headers = {
      access_token: appData.loginData.access_token,
    };
    const reqData = {
      reqBody: reqBodyWithRecurring,
      headers: headers,
    };
    loader.showLoader();

    if (isRecurringEdit) {
      return ApiService.put(CreateJobEndpoints.updateRecurringDetails(reqData))
        .then(async (res: any) => {
          loader.hideLoader();
          const eventData = {
            type: "refreshRecurringDataTable",
            data: true,
          };
          headerDropdownService.emit(eventData);
          handleClose();
          toaster.addToast({
            message: t(`Input: ${res.message}`),
            timeout: 3000,
            type: "sucess",
          });
        })
        .catch((err: any) => {
          loader.hideLoader();

          toaster.addToast({
            message: t(`Input: ${err.message}`),
            timeout: 3000,
            type: "error",
          });
        });
    }

    return ApiService[operation === CreateTaskOperation.EDIT ? "put" : "post"](
      CreateJobEndpoints.crateJob(
        reqData,
        operation,
        repeatObj.isRecurringEnabled
      )
    )
      .then(async (res: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: t(`Input: ${res.message}`),
          timeout: 3000,
          type: "success",
        });
        if (reqBody.workflow_type === 1) {
          const data: any = {
            type: "fetchJobs",
            data: true,
          };
          headerDropdownService.emit(data);

          const resetData: any = {
            type: "setDateTime",
            data: true,
          };
          headerDropdownService.emit(resetData);
        }
        let resultFromUpdate: any = false;
        if (
          appData.loginData.onboard_step < 6 &&
          appData.loginData.onboard_step
        ) {
          resultFromUpdate = await UpdateOnboardStep();
        } else {
          resultFromUpdate = true;
        }

        handleClose();

        if (res.data.is_registration_completed === 0) {
          //history.push({ pathname: "/resetpassword", search: "access_token=" + res.data.access_token + '&is_already_logged_in=1' });
        } else {
          // dispatch(actions.onLoginResponse(res.data));
          // history.push("/dashboard");
        }
        if (resultFromUpdate) {
          const eventData = {
            type: "refreshTaskList",
            data: true,
          };
          headerDropdownService.emit(eventData);
        }
      })
      .catch((err: any) => {
        loader.hideLoader();

        toaster.addToast({
          message: t(`Input: ${err.message}`),
          timeout: 3000,
          type: "error",
        });
      });
  };
  //--------------------------------------------------------------------------------------------------
  const UpdateOnboardStep = async () => {
    const res: any = await useUpdateOnboardStep({
      appData,
      ApiService,
      onBoardStep: appData.loginData.onboard_step + 1,
    });

    if (res) {
      dispatch(actions.onLoginResponse(res));

      setTimeout(() => {
        setActiveTab(3);

        resetData();
        setOpen(false);
        props.showJobInfoPopup(true);
        return true;
      });
    } else if (res === false) {
      return true;
    }
  };
  const resetCreateTaskForm = () => {
    for (let i = pickupFields.length; i >= 0; i--) {
      pickupRemove(i);
    }

    for (let j = deliveryFields.length; j >= 0; j--) {
      deliveryRemove(j);
    }

    deliveryFields.length = 0;
    pickupFields.length = 0;
    reset();
    if (Permission && Permission["Read_Teams"]) {
      getTeamList();
    }
    if (workflow === 1) {
      setExpanded(`accordion01`);
    }
    setTeamId(null);
    setGeofence(0);
    setActiveTab(0);
    updateSelectedTags([]);
    updateSelectedResource(null);
    setLocationHelperText(false);
    updateRepeatObj({
      cycle: 0,
      cycle_def: {
        0: true,
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
      },
      endDate: "",
      startOn: "",
      occurrences: "",
      recurringName: "",
      scheduleEndCriteria: "0",
      isRecurringEnabled: false,
    });
    setJobDataForEditTask({});
  };
  const getTemplateList = async () => {
    let headers = {
      access_token: access_token,
    };

    let reqData = {
      reqBody: {
        skip: 0,
        limit: 1000,
      },
      headers: headers,
    };
    let tempTemplateList: any = [];
    await ApiService.get(templateApiService.getTemplateList(reqData))
      .then((res: any) => {
        setTemplateList(res.data.data);
        tempTemplateList = res.data.data;
      })
      .catch((err) => {
        setTemplateList([]);
      });
    return tempTemplateList;
  };
  const setTeamListDropdown = (data: any) => {
    if (!data || !data.length) {
      data = [{ team_id: 0, team_name: t("Createjob:No team found") }];
      setTeamId({ team_id: 0, team_name: t("Createjob:No team found") });
      setTeamList(data);
      return;
    }
    let arr: any = data;

    setTeamList(arr);
  };
  const getTeamList = async (data?: any) => {
    // return;
    let headers = {
      access_token: access_token,
    };
    let qureyParams: any = {
      limit: 1000,
      skip: 0,
    };

    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    const res: any = await ApiService.get(CommonEndpoints.getTeamList(reqData));
    if (res.data.data) {
      setTeamListDropdown(res.data.data);
    }
  };
  const getTeamFilteredByGeofence = async (data: any) => {
    let headers = {
      access_token: access_token,
    };
    let qureyParams: any = {};
    if (data.length > 0) {
      qureyParams["filter_teams"] = true;
      qureyParams["point"] = data;
    }
    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    const res: any = await ApiService.get(
      CreateJobEndpoints.getFilteredTeamByGeofence(reqData)
    );
    if (res.data) {
      setTeamListDropdown(res.data);
    } else {
      setTeamListDropdown([]);
    }
  };
  const getAssignment = async (teamId: any) => {
    let headers = {
      access_token: access_token,
    };
    let qureyParams: any = {
      team_id: teamId ? teamId : 0,
    };
    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    const res: any = await ApiService.get(
      CommonEndpoints.getAutoAllocation(reqData)
    );
    const assignmentDetails: any = res.data;
    setAssignmentData(res.data);
    assignmentDetails.is_enabled
      ? setIsAutoAllocation(true)
      : setIsAutoAllocation(false);
  };
  const handleChangeAutoAssignmentToggle = (event: any) => {
    setIsAutoAllocation(event.target.checked);
    // onSubmitOnSwitch(rowData, event.target.checked);
  };
  const [showRecurring, setShowRucurring]: any = useState(true);

  useEffect(() => {
    //  setShown(true)
    if (Permission && Permission["Read_Teams"]) {
      getTeamList();
    }
    getActivatedExtensions();
    getTagList();
    if (activatedExtension?.includes(extensionList.capacityManagement.id)) {
      getExtensionDetail();
    }
  }, []);
  const getTagList = async (teamId?: any) => {
    let headers = {
      access_token: access_token,
    };
    let qureyParams: any = {
      limit: 1000,
      skip: 0,
      validate_resource_tags: true,
    };
    if (teamId) {
      qureyParams.team_id = teamId;
    }
    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    const res: any = await ApiService.get(CommonEndpoints.getTagsList(reqData));
    if (res.data.data && res.data.data.length > 0) {
      // preSavedTagList= res.data.data;
      updatePreSavedTagList(res.data.data);
    } else {
      updatePreSavedTagList([]);
      updateSelectedTags([]);
    }
    // setTeamListDropdown(res.data.data)
  };
  const getExtensionDetail = async () => {
    let headers = {
      access_token: access_token,
    };
    let request: any = {
      extension_id: extensionList.capacityManagement.id,
    };
    let reqData = {
      reqBody: request,
      headers: headers,
    };
    const res: any = await ApiService.get(
      extensionService.getExtensionsDetails(reqData)
    );
    setUnit(res.data[0]?.config?.unit);
  };
  const getActivatedExtensions = () => {
    if (useLocalStorage.getItem(`activatedExtension`)) {
      let data: any = localStorage.getItem(`activatedExtension`);
      let activatedExtension: any = JSON.parse(data);

      if (!activatedExtension.includes(3)) {
        setShowRucurring(false);
      }
      if (activatedExtension.includes(extensionList.barcodeExtension.id)) {
        setShowBarcode(true);
      }
      if (!activatedExtension.includes(extensionList.barcodeExtension.id)) {
        setShowBarcode(false);
      }
    } else {
      setShowRucurring(false);
      setShowBarcode(false);
    }
  };
  const addTaskToJob = (taskType: number) => {
    let taskData: any = {
      taskDescription: "",
      name: "",
      email: "",
      template: null,
      task_type: 0,
      referenceId: "",
      capacity: null,
      local_datetime: null,
      location: "",
      lat: "",
      lng: "",
      country_code: "",
      phone: "",
    };
    if (showServiceTime) {
      taskData.service_time = "";
    }
    // if (workflow === 1) {
    let accordionId = "";
    if (taskType === 0) {
      pickupAppend(taskData);
      accordionId = "accordion" + pickupFields.length + taskType;
    } else {
      taskData["local_enddatetime"] = null;
      deliveryAppend(taskData);
      accordionId = "accordion" + deliveryFields.length + taskType;
    }
    if (workflow === 1) {
      setExpanded(accordionId);
    }
  };
  const dialCode: any = useRef<any>();
  const countryCode: any = useRef();
  const fetchDialCode = (
    dialcode: any,
    countrycode: any,
    taskType: number,
    index: number
  ) => {
    dialCode.current = "+" + dialcode;
    countryCode.current = countrycode.toUpperCase();
    // debugger;
    if (taskType === 0) {
      if (pickupFields[index]) {
        pickupFields[index]["dialCode"] = dialCode.current;
        pickupFields[index]["country_code"] = dialCode.current;
      }
    } else {
      if (deliveryFields[index]) {
        deliveryFields[index]["dialCode"] = dialCode.current;
        deliveryFields[index]["country_code"] = dialCode.current;
      }
    }
  };
  const updateGeofenceDropdownWhenDeletingTask = (taskType: number) => {
    if (
      pickupFields.length === 1 ||
      (pickupFields.length === 0 && deliveryFields.length === 1)
    ) {
      setGeofence(0);
    }
  };
  const deleteTask = (event: any, index: any, taskType: number) => {
    event.stopPropagation();
    updateGeofenceDropdownWhenDeletingTask(taskType);

    if (taskType === 0) {
      pickupRemove(index);
      if (index === 0 && pickupFields.length < 2) {
        addTaskToJob(0);
        setExpanded(`accordion00`);
      }
    } else {
      deliveryRemove(index);

      if (index === 0 && deliveryFields.length < 2) {
        addTaskToJob(1);
        setExpanded(`accordion01`);
      }
    }
  };
  const fetchAddress = (arg: any, index: number, taskType: number) => {
    // setLatLngInputShrink(false)
    if (taskType === 0) {
      pickupFields[index]["location"] = arg.address;
      pickupFields[index]["lat"] = arg.latLng?.latitude;
      pickupFields[index]["lng"] = arg.latLng?.longitude;
      setMapList({
        lat: arg.latLng?.latitude,
        lng: arg.latLng?.longitude,
      });
      setValue(`task[${taskType}][${index}].lat`, pickupFields[index]["lat"]);
      setValue(`task[${taskType}][${index}].lng`, pickupFields[index]["lng"]);
    } else {
      deliveryFields[index]["location"] = arg.address;
      deliveryFields[index]["lat"] = arg.latLng?.latitude;
      deliveryFields[index]["lng"] = arg.latLng?.longitude;
      setMapList({
        lat: arg.latLng?.latitude,
        lng: arg.latLng?.longitude,
      });
      setValue(`task[${taskType}][${index}].lat`, deliveryFields[index]["lat"]);
      setValue(`task[${taskType}][${index}].lng`, deliveryFields[index]["lng"]);
    }
    setLatInputShrink(true);
    setLngInputShrink(true);
  };
  useEffect(() => {
    getAutoCompleteContactValues(debouncedSearchTerm.trim());
  }, [debouncedSearchTerm.trim()]);
  const setAddressFromMarker = (markerAddress: any) => {
    setAutocompleteMarkerAddress(markerAddress);

    setAutocomplete.current.onMarkerChange();
  };
  const onTemplateChange = (data: any) => {
    const templateFields = data.data?.template_id
      ? templateMethodService.getTemplateFieldsByTemplateId(
          data.data.template_id,
          templateList
        )
      : [];
    if (data.taskType === 0) {
      pickupFields[data.index]["templateFieldList"] = JSON.parse(
        JSON.stringify(templateFields)
      );
    } else {
      deliveryFields[data.index]["templateFieldList"] = JSON.parse(
        JSON.stringify(templateFields)
      );
    }
    data.taskType === 0
      ? (pickupFields[data.index]["template"] = data.data
          ? { ...data.data }
          : null)
      : (deliveryFields[data.index]["template"] = data.data
          ? { ...data.data }
          : null);
    updateStateForReRenderTemplateFieldList(Math.random());
  };
  const handleTemplateFieldChange = (data: any) => {
    data.taskType === 0
      ? (pickupFields[data.taskIndex]["templateFieldList"][data.fieldIndex][
          "value"
        ] = data.value)
      : (deliveryFields[data.taskIndex]["templateFieldList"][data.fieldIndex][
          "value"
        ] = data.value);
    if (data.checklist) {
      data.taskType === 0
        ? (pickupFields[data.taskIndex]["templateFieldList"][data.fieldIndex][
            "checklist"
          ] = data.checklist)
        : (deliveryFields[data.taskIndex]["templateFieldList"][data.fieldIndex][
            "checklist"
          ] = data.checklist);
    }
  };

  const setPrefillData = (value: any, taskType: any, i: any) => {
    let found: any = false;
    for (let t = 0; t < autocompleteResponse.length; t++) {
      let index: any = autocompleteResponse[t];
      if (value && value.customer_id === index.customer_id) {
        if (index.email !== undefined && index.email !== null) {
          setValue(`task[${taskType}][${i}].email`, index.email);
          taskType === 0
            ? (pickupFields[i]["email"] = index.email)
            : (deliveryFields[i]["email"] = index.email);
        }
        if (index.name) {
          setValue(`task[${taskType}][${i}].name`, index.name);
          nameAutocomplete.current.value = index.name;

          {
            taskType === 0
              ? (pickupFields[i]["name"] = index.name)
              : (deliveryFields[i]["name"] = index.name);
          }
          console.log(pickupFields[i]["name"], "234234234");
          setNameIndex(
            taskType === 0 ? pickupFields[i]["name"] : deliveryFields[i]["name"]
          );
        }
        if (index.phone_number) {
          setValue(`task[${taskType}][${i}].phone`, index.phone_number);
          {
            taskType === 0
              ? (pickupFields[i]["phone"] = index.phone_number)
              : (deliveryFields[i]["phone"] = index.phone_number);
          }
          {
            taskType === 0
              ? (pickupFields[i]["country_code"] = index.country_code)
              : (deliveryFields[i]["country_code"] = index.country_code);
          }
        }

        if (index.default_address) {
          if (index?.default_address.address) {
            {
              taskType === 0
                ? (pickupFields[i]["location"] = index.default_address?.address)
                : (deliveryFields[i]["location"] =
                    index.default_address?.address);
            }
            setAutocomplete.current.onValueChange(
              index.default_address?.address
            );
            setValue(
              `task[${taskType}][${i}].location`,
              index.default_address?.address
            );
          } else {
            {
              taskType === 0
                ? (pickupFields[i]["location"] = "")
                : (deliveryFields[i]["location"] = "");
            }
            setAutocomplete.current.onValueChange("");
            setValue(`task[${taskType}][${i}].location`, "");
          }
        }
        if (index.default_address?.lat && index.default_address?.lng) {
          setValue(`task[${taskType}][${i}].lat`, index.default_address?.lat);
          setValue(`task[${taskType}][${i}].lng`, index.default_address?.lng);
          if (taskType === 0) {
            pickupFields[i]["lat"] = index.default_address?.lat;
            pickupFields[i]["lng"] = index.default_address?.lng;
          } else {
            deliveryFields[i]["lat"] = index.default_address?.lat;
            deliveryFields[i]["lng"] = index.default_address?.lng;
          }
          setMapList({
            lat: index.default_address?.lat,
            lng: index.default_address?.lng,
          });
          if (!index.default_address && !index.address[0]?.lat) {
            setValue(`task[${taskType}][${i}].lat`, "");
            setValue(`task[${taskType}][${i}].lng`, "");
            setValue(`task[${taskType}][${i}].location`, "");

            taskType === 0
              ? (pickupFields[i]["location"] = "")
              : (deliveryFields[i]["location"] = "");

            if (taskType === 0) {
              pickupFields[i]["lat"] = "";
              pickupFields[i]["lng"] = "";
            } else {
              deliveryFields[i]["lat"] = "";
              deliveryFields[i]["lng"] = "";
            }
            setMapList({
              lat: "",
              lng: "",
            });
          }
        } else {
          if (index.address) {
            if (index?.address.length != 0) {
              {
                taskType === 0
                  ? (pickupFields[i]["location"] = index.address[0]?.location)
                  : (deliveryFields[i]["location"] =
                      index.address[0]?.location);
              }
              setAutocomplete.current.onValueChange(index.address[0]?.location);
              setValue(
                `task[${taskType}][${i}].location`,
                index.address[0]?.location
              );
            } else {
              {
                taskType === 0
                  ? (pickupFields[i]["location"] = "")
                  : (deliveryFields[i]["location"] = "");
              }
              setAutocomplete.current.onValueChange("");
              setValue(`task[${taskType}][${i}].location`, "");
            }
          }
          if (index.address[0]?.lat && index.address[0]?.lng) {
            setValue(`task[${taskType}][${i}].lat`, index.address[0]?.lat);
            setValue(`task[${taskType}][${i}].lng`, index.address[0]?.lng);
            if (taskType === 0) {
              pickupFields[i]["lat"] = index.address[0]?.lat;
              pickupFields[i]["lng"] = index.address[0]?.lng;
            } else {
              deliveryFields[i]["lat"] = index.address[0]?.lat;
              deliveryFields[i]["lng"] = index.address[0]?.lng;
            }
            setMapList({
              lat: index.address[0]?.lat,
              lng: index.address[0]?.lng,
            });
          }
        }

        found = true;
        // setPrefillAddressdata(false);
        break;
      } else {
        found = false;
      }

      if (value === null) {
      }
    }
    if (!found && value) {
      taskType === 0
        ? (pickupFields[i]["name"] = value.title)
        : (deliveryFields[i]["name"] = value.title);
    }
  };
  const getAutoCompleteContactValues = async (value: any, type?: any) => {
    let headers: any = {
      access_token: access_token,
    };
    let request: any = {};
    if (type === "email") {
      request.email = value;
    }

    request.search = value;

    let reqData: any = {
      headers: headers,
      reqBody: request,
    };

    if (value.length >= 3) {
      const res = await ApiService.get(
        CreateJobEndpoints.getContactAutocomplete(reqData)
      );
      // console.log(res, "23323232");
      let response: any = res.data.data;
      let nameList: any = [];
      let i = 0;

      res.data.data.map((index: any) => {
        if (index.name)
          nameList.push({
            id: ++i,
            title: `${index.name} (${
              index?.default_address
                ? index?.default_address?.address || "No Location"
                : index?.address
                ? index?.address[0]?.location
                  ? index?.address[0]?.location
                  : "No Location"
                : "No Location"

              // index?.address
              //   ? index?.address[0]?.location
              //     ? index?.address[0]?.location
              //     : index?.default_address
              //     ? index?.default_address?.address
              //     : "No Location"
              //   : "No Location"
            } )`,
            name: index.name || "",
            customer_id: index.customer_id,
          });
      });
      i = 0;
      for (let t = 0; t < res.data.data.length; t++) {
        response[t] = { ...response[t], id: ++i };
      }
      setAutocompleteResponse(response);
      searchTerm.taskType === 0
        ? setPickupAutocompleteNameList(nameList)
        : setDeliveryAutocompleteNameList(nameList);
    }
  };
  const onTeamChange = (event: any, newValue: any) => {
    setTeamId(newValue);
    updateSelectedResource(null);
    getTagList(newValue?.team_id);
    updateSelectedTags([]);
    updatePropsForFilteringResource({
      type: "team",
      value: newValue?.team_id || null,
    });
    getAssignment(newValue?.team_id || 0);
  };
  const updatePropsForFilteringResource = (data: any) => {
    let tempObj = { ...dataForResourceFiltering };
    tempObj.teamId = selectedTeamId?.team_id;
    tempObj.geofence = selectedGeofence;
    tempObj.selectedTags = selectedTags;
    tempObj.resourceId = null;
    switch (data.type) {
      case "team":
        tempObj.teamId = data.value;
        break;
      case "geofence":
        tempObj.geofence = data.value;
        break;
      case "tags":
        tempObj.selectedTags = data.value;
        break;
      case "resource":
        tempObj.resourceId = data.value;
        break;
      case "all": {
        tempObj.teamId = data.teamId;
        tempObj.geofence = data.geofence;
        tempObj.selectedTags = data.tags;
        tempObj.resourceId = data.resourceId;
      }
    }

    if (tempObj.geofence) {
      // setLocationForGetResourceList
      if (pickupFields.length > 0 && pickupFields[0]["lat"]) {
        tempObj.locationForGetResourceList = {
          lat: pickupFields[0]["lat"],
          lng: pickupFields[0]["lng"],
        };
      } else if (deliveryFields.length > 0 && deliveryFields[0]["lat"]) {
        tempObj.locationForGetResourceList = {
          lat: deliveryFields[0]["lat"],
          lng: deliveryFields[0]["lng"],
        };
      }
    }
    setDataForResourceFiltering(tempObj);
  };
  const onGeofenceChange = (event: any) => {
    setGeofence(parseInt(event.target.value));
    updateSelectedResource(null);
    updatePropsForFilteringResource({
      type: "geofence",
      value: event.target.value,
    });
    if (Permission && Permission["Read_Teams"]) {
      updatePropsForFilteringTeam(event.target.value);
    }
  };
  const updatePropsForFilteringTeam = (geofenceValue: any, jobData?: any) => {
    setTeamId({});
    let tempObj: any = {};
    if (geofenceValue) {
      // setLocationForGetResourceList
      tempObj.pointsArray = [];
      tempObj.geofence = geofenceValue;
      if (jobData) {
        jobData.tasks.map((item: any) => {
          tempObj.pointsArray.push(item.lat + " " + item.lng);
        });
      } else {
        if (pickupFields.length > 0) {
          pickupFields.map((item: any) => {
            if (item.lat && item.lng) {
              tempObj.pointsArray.push(item.lat + " " + item.lng);
            }
          });
        }
        if (deliveryFields.length > 0) {
          deliveryFields.map((item: any) => {
            if (item.lat && item.lng) {
              tempObj.pointsArray.push(item.lat + " " + item.lng);
            }
          });
        }
      }
      return getTeamFilteredByGeofence(tempObj.pointsArray);
    }
    getTeamList(tempObj);
  };

  const handleKeyDownInTagsInput = (event: any) => {
    switch (event.key) {
      case ",":
      case " ": {
        event.preventDefault();
        event.stopPropagation();
        if (event.target.value.length > 0) {
          updateSelectedTags([...selectedTags, event.target.value]);
        }
        break;
      }
      default:
    }
  };
  const updateDateFromPicker = (
    data: any,
    index: number,
    taskType: number,
    isEndDatetime?: boolean
  ) => {
    handleDateChange(data);
    if (isEndDatetime) {
      deliveryFields[index][
        "local_enddatetime"
      ] = DateTimeFormatter.changeOnlyFormatInSameTimeZone(
        data,
        "YYYY-MM-DD HH:mm"
      );
    } else {
      if (taskType === 0) {
        pickupFields[index][
          "local_datetime"
        ] = DateTimeFormatter.changeOnlyFormatInSameTimeZone(
          data,
          "YYYY-MM-DD HH:mm"
        );
      } else {
        deliveryFields[index][
          "local_datetime"
        ] = DateTimeFormatter.changeOnlyFormatInSameTimeZone(
          data,
          "YYYY-MM-DD HH:mm"
        );
      }
    }
  };
  const CustomPaper = (props: any) => {
    return <Paper elevation={4} {...props} />;
  };
  const switchWorkFlow = (newValue: number) => {
    setWorkflow(newValue);
    resetCreateTaskForm();
    if (newValue === 1) {
      setTimeout(() => {
        addTaskToJob(1);
      }, 300);
    }
    useLocalStorage.setItem("workflow", newValue);
  };
  const getSelectedOptionForName = (
    index: number,
    taskType: number,
    optionGet: any
  ) => {
    setSelectedOption(optionGet);
    const option =
      taskType === 0
        ? namePickupList.length > 0
          ? namePickupList.find((v: any) => v.id === optionGet.id)
          : pickupFields[index]["name"]
        : nameDeliveryList.length > 0
        ? nameDeliveryList.find((v: any) => v.id === optionGet.id)
        : deliveryFields[index]["name"];
    console.log(option, "Option");
    return option;
  };
  const AddTaskToJobArrayInHtmlView = (
    item: any,
    index: number,
    taskType: number
  ) => {
    return (
      <div
        key={item.id}
        className="CreateJob__form__task-accordion"
        style={
          operation === CreateTaskOperation.EDIT
            ? { marginBottom: "15px" }
            : workflow === 1 && expanded != "accordion" + index + taskType
            ? { marginTop: "1.3rem", marginBottom: "1.7rem" }
            : {}
        }
      >
        <Accordion
          className={
            expanded === "accordion" + index + taskType
              ? classes.expanded
              : classes.root
          }
          expanded={expanded === "accordion" + index + taskType ? true : false}
          // onChange={handleChange("accordion" + index + taskType)}
        >
          <AccordionSummary
            className={
              expanded === "accordion" + index + taskType
                ? classes.expanded
                : classes.root
            }
            // expandIcon={<EditIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Grid
              container
              alignItems="center"
              justify="space-around"
              onClick={() => {
                setExpanded("accordion" + index + taskType);
              }}
            >
              <Grid item xs={2}>
                <Typography
                  className={
                    expanded === "accordion" + index + taskType
                      ? "CreateJob__form__task-accordion__job-type-heading"
                      : "CreateJob__form__task-accordion__job-type-collapse-heading"
                  }
                >
                  {workflow === 0
                    ? taskType === 0
                      ? t("Common:Pickup") +
                        t(` ${pickupFields.length > 1 ? index + 1 : ""}`)
                      : t(`Common:Delivery`) +
                        (deliveryFields.length > 1 ? index + 1 : "")
                    : t(
                        `Common:Service` +
                          (deliveryFields.length > 0 &&
                          expanded != "accordion" + index + taskType
                            ? index + 1
                            : "")
                      )}
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Grid
                  container
                  className="CreateJob__form__task-accordion__heading-detail__container"
                >
                  <Grid item xs={11}>
                    {expanded != "accordion" + index + taskType && (
                      <Grid
                        container
                        alignItems="center"
                        justify="space-around"
                      >
                        <Grid item xs={4}>
                          <Typography>
                            {workflow === 0
                              ? taskType === 0
                                ? pickupFields[index]["local_datetime"] &&
                                  DateTimeFormatter.changeOnlyFormatInSameTimeZone(
                                    pickupFields[index]["local_datetime"],
                                    `${DateTimeFormatter.getTimeFormat()} ${DateTimeFormatter.getDateFormat()}`
                                  )
                                : deliveryFields[index]["local_datetime"] &&
                                  DateTimeFormatter.changeOnlyFormatInSameTimeZone(
                                    deliveryFields[index]["local_datetime"],
                                    `${DateTimeFormatter.getTimeFormat()} ${DateTimeFormatter.getDateFormat()}`
                                  )
                              : deliveryFields[index]["local_datetime"] &&
                                deliveryFields[index]["local_enddatetime"]
                              ? deliveryFields[index]["local_datetime"] &&
                                DateTimeFormatter.changeOnlyFormatInSameTimeZone(
                                  deliveryFields[index]["local_datetime"],
                                  `${DateTimeFormatter.getTimeFormat()} ${DateTimeFormatter.getDateFormat()}`
                                ) +
                                  " - " +
                                  deliveryFields[index]["local_enddatetime"] &&
                                DateTimeFormatter.changeOnlyFormatInSameTimeZone(
                                  deliveryFields[index]["local_enddatetime"],
                                  `${DateTimeFormatter.getTimeFormat()} ${DateTimeFormatter.getDateFormat()}`
                                )
                              : null}
                          </Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Typography>
                            {workflow === 0
                              ? taskType === 0
                                ? pickupFields[index]["location"]
                                : deliveryFields[index]["location"]
                              : deliveryFields[index]["local_datetime"] &&
                                deliveryFields[index]["local_enddatetime"]
                              ? deliveryFields[index]["local_datetime"] +
                                " - " +
                                deliveryFields[index]["local_enddatetime"]
                              : null}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{ display: "flex", marginLeft: "-15px" }}
                  >
                    {workflow === 0 &&
                    expanded !== "accordion" + index + taskType ? (
                      <>
                        <EditIcon
                          style={{ color: `${themeColor.primary.main}` }}
                        />
                        <button
                          type="button"
                          className="CreateJob__form__task-accordion__delete-icon"
                          onClick={(event) => {
                            deleteTask(event, index, taskType);
                          }}
                        >
                          {" "}
                          {operation != CreateTaskOperation.EDIT ? (
                            <DeleteOutlinedIcon
                              style={{ color: `${themeColor.primary.main}` }}
                            />
                          ) : (
                            ""
                          )}
                        </button>
                      </>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.padding0}>
            <Grid container spacing={1}>
              <Grid item sm={6} xs={12} className={classes.formInput}>
                <AutocompleteMaterial
                  disablePortal={true}
                  size="medium"
                  id={`task[${taskType}][${index}].name`}
                  ref={nameAutocomplete}
                  options={taskType == 0 ? namePickupList : nameDeliveryList}
                  // getOptionLabel={(option: any) => option.name}
                  getOptionLabel={(option: any) =>
                    props.showDuplicates
                      ? taskType === 0
                        ? namePickupList[index]
                          ? namePickupList[index].title
                          : option || option.name
                        : nameDeliveryList[index]
                        ? nameDeliveryList[index].title
                        : option.name
                      : taskType === 0
                      ? pickupFields[index]["name"]
                      : deliveryFields[index]["name"]
                  }
                  // getOptionSelected={(option, value) =>
                  // getSelectedOptionForName(index, taskType,value)
                  // }
                  clearText={""}
                  defaultValue={
                    taskType === 0
                      ? namePickupList.length > 0
                        ? namePickupList.find(
                            (v: any) => v.name === pickupFields[index]["name"]
                          )
                        : pickupFields[index]["name"]
                      : // "Chetan Dhiman Default"
                      nameDeliveryList.length > 0
                      ? nameDeliveryList.find(
                          (v: any) => v.name === deliveryFields[index]["name"]
                        )
                      : deliveryFields[index]["name"]
                  }
                  // value={nameIndex}
                  renderOption={(option: any) => (
                    <React.Fragment>{option.title}</React.Fragment>
                  )}
                  // defaultValue={
                  //   taskType === 0
                  //   ? namePickupList.length > 0
                  //     ? namePickupList.find(
                  //         (v: any) => v.name === pickupFields[index]["name"]
                  //       )
                  //     : pickupFields[index]["name"]
                  //   : nameDeliveryList.length > 0
                  //   ? nameDeliveryList.find(
                  //       (v: any) => v.name === deliveryFields[index]["name"]
                  //     )
                  //   : deliveryFields[index]["name"]
                  // }
                  freeSolo
                  onChange={(event: any, newValue: any) =>
                    setPrefillData(newValue, taskType, index)
                  }
                  renderInput={(params: any) => {
                    return (
                      <>
                        {/* {pickupFields[index]["name"]} */}

                        <TextField
                          {...params}
                          id={`task[${taskType}][${index}].name`}
                          name={`task[${taskType}][${index}].name`}
                          inputRef={register()}
                          onChange={(e: any) => {
                            taskType === 0
                              ? (pickupFields[index]["name"] = e.target.value)
                              : (deliveryFields[index]["name"] =
                                  e.target.value);
                            setSearchTerm({ term: e.target.value, taskType });
                          }}
                          value={searchTerm.term}
                          // value={item.name}
                          // defaultValue={item.name}
                          label={t("Common:Name")}
                          variant={"outlined"}
                          InputLabelProps={
                            taskType === 0 && pickupFields[index]["name"]
                              ? {
                                  shrink: true,
                                }
                              : taskType === 1 && deliveryFields[index]["name"]
                              ? {
                                  shrink: true,
                                }
                              : {}
                          }
                        />
                      </>
                    );
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12} className={classes.formInput}>
                <Phone
                  hideLabel={true}
                  showPhone={true}
                  setDialCode={(dialcode: any, countrycode: any) => {
                    fetchDialCode(dialcode, countrycode, taskType, index);
                  }}
                  name={`task[${taskType}][${index}].phone`}
                  id={`task[${taskType}][${index}].phone`}
                  label={"Mobile Number"}
                  placeholder={t("Common:Enter Mobile Number") + "*"}
                  defaultPhone={
                    taskType === 0
                      ? pickupFields[index]["country_code"]
                      : deliveryFields[index]["country_code"]
                  }
                  phoneNumber={
                    taskType === 0
                      ? pickupFields[index]["phone"]
                      : deliveryFields[index]["phone"]
                  }
                  errors={errors}
                  refValue={register(
                    // {
                    //   validate: (value: any) => {
                    //     if (!value) {
                    //       openAccordion("accordion" + index + taskType);
                    //       const errMsg: any = t(
                    //         "Validation:This field is required."
                    //       );
                    //       return errMsg;
                    //     } else {
                    //       return true;
                    //     }
                    //   },
                    // }
                    obj.phone
                  )}
                ></Phone>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={6} className={classes.formInput}>
                    <Input
                      type="text"
                      name={`task[${taskType}][${index}].email`}
                      id={`task[${taskType}][${index}].email`}
                      value={
                        taskType === 0
                          ? pickupFields[index]["email"]
                          : deliveryFields[index]["email"]
                      }
                      errors={errors}
                      refValue={register({
                        validate: (value: any) => {
                          let pattern: any = new RegExp(
                            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
                          );
                          if (value && !pattern.test(value)) {
                            openAccordion("accordion" + index + taskType);
                            const errMsg: any = t(
                              "Validation:Please enter a valid email address."
                            );
                            return errMsg;
                          } else {
                            return true;
                          }
                        },
                      })}
                      InputLabelProps={
                        taskType === 0 && pickupFields[index]["email"]
                          ? {
                              shrink: true,
                            }
                          : taskType === 1 && deliveryFields[index]["email"]
                          ? {
                              shrink: true,
                            }
                          : {}
                      }
                      label={t("Common:Email ID")}
                    ></Input>
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      type="text"
                      name={"taskReferenceId" + taskType + index}
                      id={`task[${taskType}][${index}].referenceId`}
                      value={
                        taskType === 0
                          ? pickupFields[index]["referenceId"]
                          : deliveryFields[index]["referenceId"]
                      }
                      refValue={register()}
                      errors={errors}
                      label={t("Common:Reference ID")}
                    ></Input>
                  </Grid>

                  <Grid
                    item
                    xs={workflow === 1 || showServiceTime ? 12 : 6}
                    className={classes.formInput}
                  >
                    <div style={{ position: "relative" }}>
                      <div
                        className="CreateJob__form__task-accordion__see-on-map"
                        onClick={() => {
                          showMap ? setShowMap(false) : setShowMap(true);

                          setTimeout(() => {
                            if (!showMap) createJobMapRef.current.handleOpen();
                            if (!showMap) {
                              scrollRefs[index].current.scrollIntoView({
                                behavior: "smooth",
                              });
                            }
                          }, 200);
                        }}
                      >
                        <Tooltip
                          content={"Show Map"}
                          direction={"top"}
                          style={{
                            backgroundColor: "rgba(97, 97, 97, 0.92)",
                            marginTop: "-10px",
                          }}
                          wrapperStyle={{
                            width: "-webkit-fill-available",
                          }}
                          hideOnClick={true}
                          // className={classes.tooltip}
                        >
                          <MapOutlinedIcon />
                        </Tooltip>
                      </div>
                      <div
                        className="CreateJob__form__task-accordion__latlng-on-map"
                        onClick={() => {
                          setshowLocation((showLocation: any) => !showLocation);
                        }}
                      >
                        <Tooltip
                          content={"Coordinates"}
                          direction={"top"}
                          style={{
                            backgroundColor: "rgba(97, 97, 97, 0.92)",
                            marginTop: "-10px",
                          }}
                          hideOnClick={true}
                          wrapperStyle={{
                            width: "-webkit-fill-available",
                          }}
                          // className={classes.tooltip}
                        >
                          <LocationOnOutlinedIcon />
                          {/* <img src={Location} /> */}
                        </Tooltip>
                      </div>

                      <Autocomplete
                        color={"#16449b"}
                        label={t("Common:Please Select a Location*")}
                        ref={setAutocomplete}
                        name={`task[${taskType}][${index}].location`}
                        latlngObject={mapList}
                        onAutocompleteLocationSelected={(arg: any) => {
                          fetchAddress(arg, index, taskType);
                          if (index === 0 && selectedGeofence) {
                            if (pickupFields) {
                              setGeofence(0);
                              updatePropsForFilteringTeam(0);
                            } else {
                              setGeofence(0);
                              updatePropsForFilteringTeam(0);
                            }
                          }
                        }}
                        InputLabelProps={
                          taskType === 0 && pickupFields[index]["location"]
                            ? {
                                shrink: true,
                              }
                            : taskType === 1 &&
                              deliveryFields[index]["location"]
                            ? {
                                shrink: true,
                              }
                            : {}
                        }
                        value={
                          taskType === 0
                            ? pickupFields[index]["location"]
                            : deliveryFields[index]["location"]
                        }
                        triggerLocationValidate={(
                          isValid: boolean,
                          inputValue?: any
                        ) => {
                          const lng =
                            taskType === 0
                              ? pickupFields[index]["lng"]
                              : deliveryFields[index]["lng"];
                          const lat =
                            taskType === 0
                              ? pickupFields[index]["lat"]
                              : deliveryFields[index]["lat"];
                          if (!isValid && !lat && !lng) {
                            // errors[`task[${taskType}][${index}].location`]=true;
                            setLocationHelperText("Unable to find location");
                          } else {
                            // errors[`task[${taskType}][${index}].location`]=false;
                            if (lat && lng) {
                              taskType === 0
                                ? (pickupFields[index]["location"] = inputValue)
                                : (deliveryFields[index][
                                    "location"
                                  ] = inputValue);
                            }
                            setLocationHelperText(false);
                          }
                        }}
                        helperText={locationHelperText}
                        errors={errors}
                        markerAddress={markerAddress}
                        changeAddressOnMarkerChange={false}
                      ></Autocomplete>
                    </div>
                  </Grid>
                  {workflow != 1 && (
                    <>
                      <Grid item sm={workflow === 1 ? 6 : 6}>
                        <div
                          className="input__box-container"
                          style={{ marginRight: "2px" }}
                        >
                          <Controller
                            control={control}
                            name={`task[${taskType}][${index}].local_enddatetime`}
                            render={(props) => (
                              <DateTimePicker
                                name={`task[${taskType}][${index}].local_datetime`}
                                showIcon={true}
                                className={
                                  "CreateJob__form__task-accordion__date-input"
                                }
                                inputRef={register()}
                                value={
                                  taskType === 0
                                    ? pickupFields[index]["local_datetime"]
                                    : deliveryFields[index]["local_datetime"]
                                }
                                disablePast={true}
                                showTimeSelect={true}
                                label={
                                  workflow === 0
                                    ? taskType === 0
                                      ? t("Common:Pickup Time*")
                                      : t("Common:Delivery Time*")
                                    : t("Createjob:Start Time") + "*"
                                }
                                onChange={(data: any) => {
                                  updateDateFromPicker(data, index, taskType);
                                }}
                                errors={errors}
                                required
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      {showServiceTime && workflow === 0 && (
                        <Grid item xs={6} className={classes.formInput}>
                          <Input
                            type="text"
                            id={`task[${taskType}][${index}].service_time`}
                            name={`task[${taskType}][${index}].service_time`}
                            refValue={register()}
                            // required={true}
                            value={
                              taskType === 0
                                ? pickupFields[index]["service_time"]
                                : deliveryFields[index]["service_time"]
                            }
                            onkeypress={() => {}}
                            errors={errors}
                            label={t("Common:Service Time(mins)")}
                          ></Input>
                        </Grid>
                      )}
                    </>
                  )}

                  {showLocation && (
                    <>
                      <Grid className={classes.formInput} item sm={6} xs={12}>
                        <Controller
                          control={control}
                          name={"latitude" + taskType + index}
                          render={() => (
                            <Input
                              type="text"
                              key={`task[${taskType}][${index}].lat`}
                              id={`task[${taskType}][${index}].lat`}
                              name={"latitude" + taskType + index}
                              refValue={register()}
                              value={
                                taskType === 0
                                  ? pickupFields[index]["lat"]
                                  : deliveryFields[index]["lat"] || ""
                              }
                              onBlur={() => {
                                if (
                                  !getValues(
                                    `task[${taskType}][${index}].lat`
                                  ).trim()
                                ) {
                                  setValue(
                                    `task[${taskType}][${index}].lat`,
                                    ""
                                  );
                                  taskType === 0
                                    ? (pickupFields[index]["lat"] = "")
                                    : (deliveryFields[index]["lat"] = "");
                                  return false;
                                }
                              }}
                              onchange={(e: any) => {
                                if (taskType === 0) {
                                  pickupFields[index]["lat"] = Number(
                                    e.target.value
                                  );
                                  setMapList({
                                    lat: pickupFields[index]["lat"],
                                    lng: pickupFields[index]["lng"],
                                  });
                                }
                                if (taskType === 1) {
                                  deliveryFields[index]["lat"] = Number(
                                    e.target.value
                                  );
                                  setMapList({
                                    lat: deliveryFields[index]["lat"],
                                    lng: deliveryFields[index]["lng"],
                                  });
                                }
                                if (e.target.value.length === 0) {
                                  setLatInputShrink(false);
                                }
                              }}
                              InputLabelProps={
                                (taskType === 0 &&
                                  pickupFields[index]["lat"]) ||
                                latInputShrink
                                  ? {
                                      shrink: true,
                                    }
                                  : (taskType === 1 &&
                                      deliveryFields[index]["lat"]) ||
                                    latInputShrink
                                  ? {
                                      shrink: true,
                                    }
                                  : {}
                              }
                              label={t("ImportJobs:Latitude")}
                            ></Input>
                          )}
                        />
                      </Grid>
                      <Grid className={classes.formInput} item sm={6} xs={12}>
                        <Controller
                          control={control}
                          name={"longitude" + taskType + index}
                          // key={`task[${taskType}][${index}].lng`}
                          // as={<Input/>}
                          render={(props) => (
                            <Input
                              type="text"
                              key={`task[${taskType}][${index}].lng`}
                              id={`task[${taskType}][${index}].lng`}
                              name={"longitude" + taskType + index}
                              refValue={register()}
                              value={
                                taskType === 0
                                  ? pickupFields[index]["lng"]
                                  : deliveryFields[index]["lng"] || ""
                              }
                              onBlur={() => {
                                if (
                                  !getValues(
                                    `task[${taskType}][${index}].lng`
                                  ).trim()
                                ) {
                                  setValue(
                                    `task[${taskType}][${index}].lng`,
                                    ""
                                  );
                                  taskType === 0
                                    ? (pickupFields[index]["lng"] = "")
                                    : (deliveryFields[index]["lng"] = "");
                                  return;
                                }
                              }}
                              onchange={(e: any) => {
                                if (taskType === 0) {
                                  pickupFields[index]["lng"] = Number(
                                    e.target.value
                                  );
                                  setMapList({
                                    lat: pickupFields[index]["lat"],
                                    lng: pickupFields[index]["lng"],
                                  });
                                }
                                if (taskType === 1) {
                                  deliveryFields[index]["lng"] = Number(
                                    e.target.value
                                  );
                                  setMapList({
                                    lat: deliveryFields[index]["lat"],
                                    lng: deliveryFields[index]["lng"],
                                  });
                                }
                                if (e.target.value.length === 0) {
                                  setLngInputShrink(false);
                                }
                              }}
                              InputLabelProps={
                                (taskType === 0 &&
                                  pickupFields[index]["lng"]) ||
                                LngInputShrink
                                  ? {
                                      shrink: true,
                                    }
                                  : (taskType === 1 &&
                                      deliveryFields[index]["lng"]) ||
                                    LngInputShrink
                                  ? {
                                      shrink: true,
                                    }
                                  : {}
                              }
                              label={t("ImportJobs:Longitude")}
                            ></Input>
                          )}
                        />
                      </Grid>
                    </>
                  )}
                  {/* <div style={{ position: "relative" }}> */}
                  <div
                    id={`mapScrollDiv${taskType}${index}`}
                    key={`mapScrollDiv${taskType}${index}`}
                    ref={scrollRefs[index]}
                    style={{ float: "left", clear: "both" }}
                  >
                    {" "}
                  </div>
                  {showMap ? (
                    <>
                      <CreateJobMap
                        setAddressFromMarker={setAddressFromMarker}
                        marker={{
                          lat:
                            taskType === 0
                              ? pickupFields[index].lat
                              : deliveryFields[index].lat,
                          lng:
                            taskType === 0
                              ? pickupFields[index].lng
                              : deliveryFields[index].lng,
                        }}
                        taskType={taskType}
                        mapId={taskType + "" + index}
                        ref={createJobMapRef}
                      ></CreateJobMap>
                    </>
                  ) : (
                    ""
                  )}

                  <div style={{ position: "relative" }} hidden={!showMap}>
                    <div
                      style={{
                        position: "absolute",
                        zIndex: 405,
                        left: "-25px",
                        top: "10px",
                      }}
                    >
                      <CancelIcon
                        style={{ fontSize: "25px", cursor: "pointer" }}
                        onClick={() => {
                          setShowMap(false);
                        }}
                      />
                    </div>
                  </div>
                  {/* </div> */}
                  {workflow === 1 && (
                    <Grid
                      container
                      spacing={1}
                      className={classes.formInput}
                      style={{ marginLeft: "1px", marginRight: "1px" }}
                    >
                      <Grid item sm={workflow === 1 ? 6 : 6}>
                        <div
                          className="input__box-container"
                          style={{ marginTop: "6px" }}
                        >
                          <Controller
                            control={control}
                            name={`task[${taskType}][${index}].local_enddatetime`}
                            render={(props) => (
                              <DateTimePicker
                                name={`task[${taskType}][${index}].local_datetime`}
                                inputRef={register()}
                                showIcon={true}
                                className={
                                  "CreateJob__form__task-accordion__date-input"
                                }
                                workFlow={workflow === 0 ? 0 : 1}
                                value={
                                  taskType === 0
                                    ? pickupFields[index]["local_datetime"]
                                    : deliveryFields[index]["local_datetime"]
                                }
                                disablePast={true}
                                showTimeSelect={true}
                                label={
                                  workflow === 0
                                    ? taskType === 0
                                      ? t("Common:Pickup Time*")
                                      : t("Common:Delivery Time*")
                                    : t("Createjob:Start Time") + "*"
                                }
                                onChange={(data: any) => {
                                  updateDateFromPicker(data, index, taskType);
                                }}
                                //helperText={dateHelperText}
                                errors={errors}
                                required
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item sm={6}>
                        {workflow === 1 ? (
                          <div
                            className="input__box-container"
                            style={{ marginTop: "6px" }}
                          >
                            <Controller
                              control={control}
                              name={`task[${taskType}][${index}].local_datetime`}
                              render={(props) => (
                                <DateTimePicker
                                  showIcon={true}
                                  name={`task[${taskType}][${index}].local_enddatetime`}
                                  className={
                                    "CreateJob__form__task-accordion__date-input"
                                  }
                                  inputRef={register()}
                                  // className={
                                  //   "CreateJob__form__task-accordion__date-input"
                                  // }
                                  value={
                                    deliveryFields[index]["local_enddatetime"]
                                  }
                                  workFlow={workflow === 0 ? 0 : 1}
                                  disablePast={true}
                                  showTimeSelect={true}
                                  label={t("Createjob:End Time") + "*"}
                                  onChange={(data: any) => {
                                    updateDateFromPicker(
                                      data,
                                      index,
                                      taskType,
                                      true
                                    );
                                  }}
                                  //helperText={dateHelperText}
                                  errors={errors}
                                  required
                                />
                              )}
                            />
                          </div>
                        ) : null}
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                <Grid item xs={12} className={classes.formInput}>
                  <Input
                    id={`task[${taskType}][${index}].description`}
                    name={"taskDescription" + taskType + index}
                    label={t("Common:Task Description")}
                    value={
                      taskType === 0
                        ? pickupFields[index]["taskDescription"]
                        : deliveryFields[index]["taskDescription"]
                    }
                    refValue={register()}
                    // multiline={true}
                    // rows={1}
                    defaultValue="Default Value"
                    // variant="outlined"
                  />
                </Grid>
                <Grid container spacing={1}>
                  {activatedExtension?.includes(
                    extensionList.capacityManagement.id
                  ) && (
                    <Grid
                      item
                      xs={
                        localStorage
                          .getItem("activatedExtension")
                          ?.includes("22") &&
                        templateList &&
                        templateList.length > 0
                          ? 6
                          : 12
                      }
                      className={classes.formInput}
                    >
                      <Input
                        type="number"
                        id={`task[${taskType}][${index}].capacity`}
                        name={`task[${taskType}][${index}].capacity`}
                        value={
                          taskType === 0
                            ? pickupFields[index]["capacity"]
                            : deliveryFields[index]["capacity"]
                        }
                        refValue={register()}
                        errors={errors}
                        label={t("Resourcelist:Quantity") + " (" + unit + ")"}
                      ></Input>
                    </Grid>
                  )}
                  {templateList && templateList.length > 0 ? (
                    <Grid
                      item
                      xs={
                        activatedExtension?.includes(
                          extensionList.capacityManagement.id
                        ) &&
                        templateList &&
                        templateList.length > 0
                          ? 6
                          : 12
                      }
                      className={classes.formInput}
                    >
                      <MaterialAutocomplete
                        isMultiple={false}
                        // className={classes["MuiAutocomplete-option"]}
                        freeSolo={false}
                        PaperComponent={CustomPaper}
                        disableClearable={false}
                        disablePortal={true}
                        size="medium"
                        id={"template-outlined" + taskType + index}
                        options={templateList}
                        ListboxProps={{
                          style: {
                            maxHeight: "200px",
                          },
                        }}
                        labelName="name"
                        value={
                          taskType === 0
                            ? pickupFields[index]["template"]
                            : deliveryFields[index]["template"]
                        }
                        onChange={(evt: any, newValue: any) => {
                          onTemplateChange({
                            data: newValue,
                            index: index,
                            taskType: taskType,
                          });
                        }}
                        filterSelectedOptions
                        onKeyDown={() => {}}
                        textFieldClassName={classes.tagInputField}
                        textFieldLabel={t("Common:Template")}
                        textFieldPlaceholder={t("Common:Template")}
                        textFieldMargin={"normal"}
                        textFieldFullWidth={true}
                      />
                    </Grid>
                  ) : null}
                </Grid>
                {(taskType === 0 &&
                  pickupFields[index]["templateFieldList"] &&
                  pickupFields[index]["templateFieldList"].length > 0) ||
                (taskType === 1 &&
                  deliveryFields[index]["templateFieldList"] &&
                  deliveryFields[index]["templateFieldList"].length > 0) ? (
                  <RenderTemplateFieldList index={index} taskType={taskType} />
                ) : null}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  };
  const RenderTemplateFieldList = (row: any) => {
    return (
      <TemplateFieldsInputForm
        itemWidthXs={6}
        itemWidthLg={6}
        handleTemplateFieldChange={handleTemplateFieldChange}
        taskType={row.taskType}
        taskIndex={row.index}
        items={
          row.taskType === 0
            ? pickupFields[row.index]["templateFieldList"]
            : deliveryFields[row.index]["templateFieldList"]
        }
      />
    );
  };
  const AssignAgentViewInHtml = () => {
    return (
      <div className="CreateJob__form__assign-accordion">
        {/* <Accordion
          className={classes.accordionWrapper}
          expanded={expanded === "accordion-assignment"}
          onChange={handleChange("accordion-assignment")}
        >
          <AccordionSummary
            className={classes.expanded}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          > */}
        <Typography className="CreateJob__form__task-accordion__assign-type-heading">
          {t("Createjob:Assign Agent")}
        </Typography>
        {/* </AccordionSummary>
          <AccordionDetails className={classes.padding0}> */}
        <Grid item xs={1}>
          {/* { operation !== CreateTaskOperation.EDIT ? ( */}
          <button
            type="button"
            className="CreateJob__form__task-accordion__delete-icon"
            // onClick={(event) => {
            //   deleteTask(event, index, taskType);
            // }}
          >
            {" "}
            {/* <DeleteOutlinedIcon /> */}
          </button>
          {/* ) : null} */}
        </Grid>
        <Grid container spacing={1}>
          {(pickupFields.length > 0 && pickupFields[0].lat) ||
          (deliveryFields.length > 0 && deliveryFields[0].lat) ? (
            <Grid item xs={6}>
              <Dropdown
                required={true}
                labelName={t("Common:Geofence")}
                onChange={onGeofenceChange}
                selectedValue={selectedGeofence}
                itemList={geoFenceOptionList}
                uniqueId="value"
                label="label"
              ></Dropdown>
            </Grid>
          ) : null}

          <Grid item xs={6}>
            <MaterialAutocomplete
              isMultiple={false}
              freeSolo={false}
              PaperComponent={CustomPaper}
              disableClearable={
                operation !== CreateTaskOperation.EDIT ? false : true
              }
              disablePortal={false}
              size="medium"
              id={"team-outlined"}
              noOptionsText={t("Createjob:No team found")}
              options={teamList}
              labelName="team_name"
              value={selectedTeamId}
              onChange={onTeamChange}
              filterSelectedOptions
              onKeyDown={() => {}}
              textFieldClassName={classes.tagInputField}
              textFieldLabel={t("Common:Team")}
              textFieldPlaceholder={t("Common:Team")}
              textFieldMargin={"normal"}
              textFieldFullWidth={true}
            />
          </Grid>
          {preSavedTagList.length > 0 ? (
            <Grid item xs={6}>
              <MaterialAutocomplete
                isMultiple={true}
                // freeSolo={false}
                disablePortal={false}
                PaperComponent={CustomPaper}
                size="medium"
                noOptionsText={"Add Tag from Tags Manager"}
                id={"tags-outlined"}
                options={preSavedTagList}
                labelName="tag_name"
                value={selectedTags}
                onChange={(event: any, newValue: any) => {
                  updateSelectedTags(newValue);
                  updateSelectedResource(null);
                  updatePropsForFilteringResource({
                    type: "tags",
                    value: newValue,
                  });
                }}
                filterSelectedOptions
                // onKeyDown={handleKeyDownInTagsInput}
                textFieldClassName={classes.tagInputField}
                textFieldLabel={t("Common:Tags")}
                textFieldPlaceholder={t("Common:Tags")}
                textFieldMargin={"normal"}
                textFieldFullWidth={true}
              />
            </Grid>
          ) : null}
          <Grid
            item
            xs={
              assignmentData?.["is_enabled"] &&
              operation !== CreateTaskOperation.EDIT
                ? 12
                : 6
            }
            className="resource-dropdown"
          >
            {assignmentData?.["is_enabled"] &&
            operation !== CreateTaskOperation.EDIT ? (
              <div className="CreateJob__form__auto-allocation">
                {t("Common:Do you want to automatically assign agents?")}
                <div>
                  <Switch
                    checked={isAutoAllocation}
                    onChange={handleChangeAutoAssignmentToggle}
                    value="checked"
                    color="primary"
                  />
                </div>
              </div>
            ) : null}
            {isAutoAllocation === false ||
            operation === CreateTaskOperation.EDIT ? (
              <Grid
                item
                xs={
                  assignmentData?.["is_enabled"] &&
                  operation !== CreateTaskOperation.EDIT
                    ? 6
                    : 12
                }
                style={
                  assignmentData?.["is_enabled"] &&
                  operation !== CreateTaskOperation.EDIT
                    ? { width: "49%" }
                    : {}
                }
              >
                <ResourceListDropdown
                  // selectedResourceId={preSelectedResourceId}
                  onChange={(event: any, newValue: any) => {
                    updateSelectedResource(newValue?.resource_id || null);
                  }}
                  disablePortal={false}
                  name={"resource"}
                  resourceType={1}
                  textFieldClassName={classes.tagInputField}
                  labelName={t("Common:Agent")}
                  fullWidth={true}
                  dataForResourceFiltering={dataForResourceFiltering}
                  showIcon={false}
                  variant={"outlined"}
                  location="create_task"
                ></ResourceListDropdown>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        {/* </AccordionDetails> */}
        {/* </Accordion> `activatedExtension*/}
        {barcode && (
          <Grid container spacing={1}>
            <Grid style={{ marginTop: "0px" }} item xs={6}>
              <Input
                type="text"
                id="barcode"
                name="barcode"
                refValue={register(obj.barcode)}
                // required={true}
                onBlur={() => {
                  if (!getValues("barcode").trim()) {
                    setValue("barcode", "");
                  }
                }}
                errors={errors}
                label={t("Common:Barcode")}
              ></Input>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        )}
      </div>
    );
  };

  const checkInputForValidation = (array: any) => {
    let fieldArr: any = [...array];
    let inputChangeFlag: any = false;
    for (let i = 0; i < fieldArr.length; i++) {
      if (fieldArr[i].name) {
        inputChangeFlag = true;
        break;
      }
      if (fieldArr[i].email) {
        inputChangeFlag = true;
        break;
      }
      if (fieldArr[i].phone) {
        inputChangeFlag = true;
        break;
      }
      if (fieldArr[i].referenceId) {
        inputChangeFlag = true;
        break;
      }
      if (fieldArr[i].capacity) {
        inputChangeFlag = true;
        break;
      }
      if (fieldArr[i].local_datetime) {
        inputChangeFlag = true;
        break;
      }
      if (fieldArr[i].location) {
        inputChangeFlag = true;
        break;
      }
      if (fieldArr[i].lat) {
        inputChangeFlag = true;
        break;
      }
      if (fieldArr[i].lng) {
        inputChangeFlag = true;
        break;
      }

      if (fieldArr[i].template) {
        inputChangeFlag = true;
        break;
      }
      if (showServiceTime && fieldArr[i].service_time) {
        inputChangeFlag = true;
        break;
      }
    }

    return inputChangeFlag;
  };

  const validationOnTabChange = async (tabValue: any, newTabValue?: any) => {
    if (tabValue === 0 && workflow === 0) {
      let result: any = [];
      for (let i = 0; i < pickupFields.length; i++) {
        let res: any = await checkInputForValidation([pickupFields[i]]);
        result.push(res);
      }
      if (result.length > 0) {
        for (let i = 0; i < pickupFields.length; i++) {
          if (result[i]) {
            if (!pickupFields[i].phone || !pickupFields[i].phone.trim()) {
              toaster.addToast({
                message:
                  workflow === 0
                    ? t("Createjob:Please fill mobile number for pickup ") +
                      " " +
                      (i + 1)
                    : t("Createjob:Please fill mobile number"),
                timeout: 3000,
                type: "error",
              });
              openAccordion("accordion" + i + 0);
              return false;
            }

            if (!pickupFields[i].location) {
              toaster.addToast({
                message:
                  t("Createjob: Please enter a valid location for pickup") +
                  " " +
                  (i + 1),
                timeout: 3000,
                type: "error",
              });
              openAccordion("accordion" + i + 0);
              return false;
            }
            let validateLatitude = await isLatitude(pickupFields[i].lat);

            if (!pickupFields[i].lat || !validateLatitude) {
              toaster.addToast({
                message:
                  t("Common:Please enter a valid latitude for pickup") +
                  " " +
                  (i + 1),
                timeout: 3000,
                type: "error",
              });
              openAccordion("accordion" + i + 0);
              return false;
            }
            let validateLongitude = await isLongitude(pickupFields[i].lng);
            if (!pickupFields[i].lng || !validateLongitude) {
              toaster.addToast({
                message:
                  t("Common:Please enter a valid longitude for pickup") +
                  " " +
                  (i + 1),
                timeout: 3000,
                type: "error",
              });
              openAccordion("accordion" + i + 0);
              return false;
            }
            if (!pickupFields[i].local_datetime) {
              toaster.addToast({
                message:
                  t("Createjob:Pickup time is required for pickup") +
                  " " +
                  (i + 1),
                timeout: 3000,
                type: "error",
              });
              openAccordion("accordion" + i + 0);
              return false;
            }
            if (
              activatedExtension?.includes(extensionList.capacityManagement.id)
            ) {
              if (!pickupFields[i]?.capacity) {
                toaster.addToast({
                  message:
                    t("Createjob:Quantity is required for pickup") +
                    " " +
                    (i + 1),
                  timeout: 3000,
                  type: "error",
                });
                openAccordion("accordion" + i + 0);
                return false;
              }
              if (pickupFields[i]?.capacity < 0) {
                toaster.addToast({
                  message:
                    t("Createjob:Quantity Can not be negative for pickup") +
                    " " +
                    (i + 1),
                  timeout: 3000,
                  type: "error",
                });
              }
            }
            if (pickupFields[i]?.templateFieldList) {
              for (
                let j = 0;
                j < pickupFields[i].templateFieldList.length;
                j++
              ) {
                if (
                  pickupFields[i].templateFieldList[j].data_type ==
                  TemplateDataTypeEnum.EMAIL
                ) {
                  if (
                    !pickupFields[i].email &&
                    pickupFields[i].templateFieldList[j].value
                  ) {
                    toaster.addToast({
                      message: t(
                        `Createjob:Please Add the primary email before adding the email in the Template in pickup ${
                          i + 1
                        }`
                      ),
                      timeout: 4000,
                      type: "error",
                    });
                    openAccordion("accordion" + i + 0);

                    return false;
                  }
                }
              }
            }

            if (
              pickupFields[i].service_time &&
              !Number(pickupFields[i].service_time)
            ) {
              toaster.addToast({
                message:
                  t("Createjob:Please enter valid service time for pickup") +
                  " " +
                  (i + 1),
                timeout: 3000,
                type: "error",
              });
              openAccordion("accordion" + i + 0);
              return false;
            }
          }
        }
      }
    }
    if (
      (tabValue === 1 && workflow === 0) ||
      (tabValue === 0 && newTabValue === 2 && workflow === 0) ||
      workflow === 1
    ) {
      let result: any = [];
      for (let i = 0; i < deliveryFields.length; i++) {
        result.push(await checkInputForValidation([deliveryFields[i]]));
      }
      let validateCapacity: any = 0;
      if (result.length > 0) {
        for (let i = 0; i < deliveryFields.length; i++) {
          if (result[i]) {
            if (!deliveryFields[i].phone || !deliveryFields[i].phone.trim()) {
              toaster.addToast({
                message:
                  workflow === 0
                    ? t("Createjob:Please fill mobile number for delivery ") +
                      " " +
                      (i + 1)
                    : t("Createjob:Please fill mobile number"),
                timeout: 3000,
                type: "error",
              });
              if (tabValue === 0 && newTabValue === 2 && workflow === 0) {
                setActiveTab(1);
              }
              openAccordion("accordion" + i + 1);
              return false;
            }
            if (!deliveryFields[i].location) {
              toaster.addToast({
                message:
                  workflow === 0
                    ? t(
                        "Createjob:Please enter a valid location for delivery "
                      ) +
                      " " +
                      (i + 1)
                    : t("Createjob:Please enter a valid location"),
                timeout: 3000,
                type: "error",
              });
              if (tabValue === 0 && newTabValue === 2 && workflow === 0) {
                setActiveTab(1);
              }
              openAccordion("accordion" + i + 1);
              return false;
            }
            if (
              activatedExtension?.includes(extensionList.capacityManagement.id)
            ) {
              if (!deliveryFields[i]?.capacity) {
                toaster.addToast({
                  message:
                    t("Createjob:Please enter Quantity for delivery") +
                    " " +
                    (i + 1),
                  timeout: 3000,
                  type: "error",
                });
                return false;
              }
              if (deliveryFields[i]?.capacity < 0) {
                toaster.addToast({
                  message:
                    t("Createjob:Delivery Can not be negative for delivery") +
                    " " +
                    (i + 1),
                  timeout: 3000,
                  type: "error",
                });
                return false;
              }
            }
            if (deliveryFields[i].templateFieldList) {
              for (
                let j = 0;
                j < deliveryFields[i].templateFieldList.length;
                j++
              ) {
                if (
                  deliveryFields[i].templateFieldList[j].data_type ==
                  TemplateDataTypeEnum.EMAIL
                ) {
                  if (
                    !deliveryFields[i].email &&
                    deliveryFields[i].templateFieldList[j].value
                  ) {
                    toaster.addToast({
                      message: t(
                        `Createjob:Please Add the primary email before adding the email in the Template in delivery ${
                          i + 1
                        }`
                      ),
                      timeout: 4000,
                      type: "error",
                    });
                    return false;
                  }
                }
              }
            }

            let validateLatitude = await isLatitude(deliveryFields[i].lat);
            if (!deliveryFields[i].lat || !validateLatitude) {
              if (workflow === 1) {
                toaster.addToast({
                  message: t("Common:Please enter a valid latitude"),

                  timeout: 3000,
                  type: "error",
                });
              } else {
                toaster.addToast({
                  message:
                    t("Common:Please enter a valid latitude for delivery") +
                    " " +
                    (i + 1),
                  timeout: 3000,
                  type: "error",
                });
              }
              openAccordion("accordion" + i + 1);
              return false;
            }
            let validateLongitude = await isLongitude(deliveryFields[i].lng);
            if (!deliveryFields[i].lng || !validateLongitude) {
              if (workflow === 0) {
                toaster.addToast({
                  message:
                    t("Common:Please enter a valid longitude for delivery") +
                    " " +
                    (i + 1),
                  timeout: 3000,
                  type: "error",
                });
              } else {
                toaster.addToast({
                  message: t("Common:Please enter a valid longitude"),
                  timeout: 3000,
                  type: "error",
                });
              }
              openAccordion("accordion" + i + 1);
              return false;
            }

            if (!deliveryFields[i].local_datetime) {
              if (workflow === 0) {
                toaster.addToast({
                  message:
                    t("Createjob:Delivery time is required for delivery") +
                    " " +
                    (i + 1),
                  timeout: 3000,
                  type: "error",
                });
              } else {
                toaster.addToast({
                  message: t("Createjob:Start time is required"),
                  timeout: 3000,
                  type: "error",
                });
              }
              if (tabValue === 0 && newTabValue === 2 && workflow === 0) {
                setActiveTab(1);
              }
              openAccordion("accordion" + i + 1);
              return false;
            }
            if (
              workflow === 0 &&
              deliveryFields[i].service_time &&
              !Number(deliveryFields[i].service_time)
            ) {
              toaster.addToast({
                message:
                  t("Createjob:Please enter valid service time for delivery") +
                  " " +
                  (i + 1),
                timeout: 3000,
                type: "error",
              });
              openAccordion("accordion" + i + 1);
              return false;
            }
            if (workflow === 1 && !deliveryFields[i].local_enddatetime) {
              toaster.addToast({
                message: t("Createjob:End time is required"),
                timeout: 3000,
                type: "error",
              });
              return false;
            }
            if (workflow === 1) {
              if (
                !moment(deliveryFields[i].local_enddatetime).isAfter(
                  moment(deliveryFields[0].local_datetime)
                )
              ) {
                toaster.addToast({
                  message: t(
                    "Createjob:End time should be greater than start time"
                  ),
                  timeout: 3000,
                  type: "error",
                });

                // openAccordion("accordion" + i + 1);
                return false;
              }
            }
            if (pickupFields.length > 0 && pickupFields[0].local_datetime) {
              if (
                !moment(deliveryFields[i].local_datetime).isAfter(
                  moment(pickupFields[0].local_datetime)
                )
              ) {
                toaster.addToast({
                  message: t(
                    `Createjob:Delivery ${
                      i + 1
                    } time should be greater than pickup time`
                  ),
                  timeout: 3000,
                  type: "error",
                });

                openAccordion("accordion" + i + 1);
                return false;
              }
            }
            if (
              activatedExtension?.includes(extensionList.capacityManagement.id)
            ) {
              validateCapacity =
                validateCapacity + Number(deliveryFields[i]?.capacity);
            }
          }
        }
        if (deliveryFields[0]?.phone && pickupFields[0]?.phone) {
          if (
            validateCapacity != totalCapacity &&
            activatedExtension?.includes(extensionList.capacityManagement.id)
          ) {
            console.log("Just for ");
            toaster.addToast({
              message: t(
                "Common:Deilvery Quantity is not equal Pickup Quantity"
              ),
              timeout: 3000,
              type: "error",
            });
            return false;
          }
        }
        for (var i = result.length - 1; i >= 1; i--) {
          if (!result[i]) {
            deliveryFields.splice(i, 1);
          }
        }
      }
    }
  };

  const handleEmptyFieldsValidation = async (tabValue: any) => {
    if (pickupFields.length > 1 && tabValue === 0) {
      let result: any = [];
      for (let i = 0; i < pickupFields.length; i++) {
        result.push(await checkInputForValidation([pickupFields[i]]));
      }
      for (var i = result.length - 1; i >= 1; i--)
        if (!result[i]) {
          pickupFields.splice(i, 1);
        }
    }
    if (deliveryFields.length > 1 && tabValue === 1) {
      let result: any = [];
      for (let i = 0; i < deliveryFields.length; i++) {
        result.push(await checkInputForValidation([deliveryFields[i]]));
      }
      for (var i = result.length - 1; i >= 1; i--)
        if (!result[i]) {
          deliveryFields.splice(i, 1);
        }
    }
  };

  const handleTabChangeFromButton = async (value: any, opr?: any) => {
    if (workflow === 0 && value?.task?.["0"]) {
      let pickupArray: any = [];

      for (let i = 0; i < value.task["0"].length; i++) {
        if (value.task["0"][i]) {
          pickupArray.push(value.task["0"][i]);
        }
      }
      for (let i = 0; i < pickupArray.length; i++) {
        if (pickupArray[i]) {
          pickupFields[i].name = pickupArray[i]?.name;
          pickupFields[i].email = pickupArray[i]?.email;
          pickupFields[i].taskDescription = pickupArray[i]?.description;
          pickupFields[i].phone = pickupArray[i]?.phone;
          pickupFields[i].defaultPhone = splitPhone(
            pickupFields[i]?.dialCode + pickupArray[i]?.phone
          );
          pickupFields[i].referenceId = pickupArray[i]?.referenceId;
          pickupFields[i].capacity = Number(pickupArray[i]?.capacity);

          if (showServiceTime) {
            pickupFields[i].service_time = pickupArray[i]?.service_time;
          }
          if (pickupArray.length == 1) {
            totalCapacity = Number(pickupArray[i]?.capacity);
          }
          if (pickupArray.length > 1 && pickupArray[i]?.capacity) {
            totalCapacity = totalCapacity + Number(pickupArray[i].capacity);
          }
        }
      }
    }
    let deliveryArray: any = [];

    if (value?.task?.["1"]) {
      for (let i = 0; i < value.task?.["1"].length; i++) {
        if (value.task["1"][i]) {
          deliveryArray.push(value.task["1"][i]);
        }
      }
      for (let i = 0; i < deliveryArray.length; i++) {
        if (deliveryArray[i]) {
          deliveryFields[i].name = deliveryArray[i]?.name;
          deliveryFields[i].email = deliveryArray[i]?.email;
          deliveryFields[i].taskDescription = deliveryArray[i]?.description;
          deliveryFields[i].phone = deliveryArray[i]?.phone;
          deliveryFields[i].defaultPhone = splitPhone(
            deliveryFields[i].dialCode + deliveryArray[i]?.phone
          );

          deliveryFields[i].referenceId = deliveryArray[i]?.referenceId;
          deliveryFields[i].capacity = deliveryArray[i]?.capacity;
          if (showServiceTime) {
            deliveryFields[i].service_time = deliveryArray[i]?.service_time;
          }
        }
      }
    }

    let checkValidation: any;
    await handleEmptyFieldsValidation(activeTab);

    if (opr != "back") {
      let tempValue = ++activeTab;
      if (workflow === 0 && tempValue === 2) {
        if (!pickupFields[0]?.phone && !deliveryFields[0].phone) {
          toaster.addToast({
            message: t("Createjob:Please add atleast one task in the job"),
            timeout: 3000,
            type: "error",
          });
          --activeTab;

          return;
        }
      }
      if (workflow === 1 && tempValue === 1) {
        if (!deliveryFields[0].phone) {
          toaster.addToast({
            message: t("Createjob:Please add atleast one task in the job"),
            timeout: 3000,
            type: "error",
          });
          --activeTab;

          return;
        }
      }
      --activeTab;
      checkValidation = await validationOnTabChange(activeTab);
    }

    if (checkValidation === false && opr !== "back") {
      return;
    }
    // let tempVal: any = activeTab;
    if (opr === "back") {
      let newValue = --activeTab;

      if (newValue === 1 && workflow === 0 && deliveryFields.length <= 1) {
        setExpanded(`accordion${0}${1}`);
      }
      if (newValue === 0 && workflow === 0 && pickupFields.length <= 1) {
        setExpanded(`accordion${0}${0}`);
      } else if (newValue === 0 && workflow === 1) {
        setExpanded(`accordion${0}${1}`);
      }
      setActiveTab(newValue);
    } else {
      let newValue = ++activeTab;

      if (newValue === 1 && workflow === 0 && deliveryFields.length <= 1) {
        setExpanded(`accordion${0}${1}`);
      } else if (newValue === 0 && workflow === 0 && pickupFields.length <= 1) {
        setExpanded(`accordion${0}${0}`);
      } else if (newValue === 0 && workflow === 1) {
        setExpanded(`accordion${0}${1}`);
      } else {
        setExpanded(``);
      }
      setActiveTab(newValue);
    }
    if (operation === CreateTaskOperation.EDIT) {
      if (opr != "back") {
        if (deliveryFields.length === 0 && workflow === 0) {
          setActiveTab(2);
        }
      } else {
        if (deliveryFields.length === 0 && workflow === 0) {
          setActiveTab(0);
          setExpanded(`accordion00`);
        }
      }
    }
    setLatInputShrink(false);
    setLngInputShrink(false);
    // if (workflow === 1) {
    //   setExpanded(`accordion${0}${1}`);
    // }
  };
  useEffect(() => {}, [activeTab]);
  const RecurringSettingsInHtml = () => {
    return (
      <div
        className="CreateJob__form__recurring"
        style={{ display: !showRecurring ? "none" : "" }}
        hidden={!Permission["Create_Recurring"]}
      >
        <CreateRecurringJob
          handleChange={handleChange}
          openManuallyAccordion={(panel: string) => {
            // setExpanded(panel ? panel : false);
          }}
          expanded={expanded}
          repeatObj={repeatObj}
          updateRepeatObj={(data: any) => updateRepeatObj(data)}
          register={register}
          setValue={setValue}
        ></CreateRecurringJob>
      </div>
    );
  };
  const handleTabChange = async (e: any, newValue: any) => {
    if (workflow === 0 && activeTab === 0) {
      let taskArray0: any = pickupFields;
      for (let i = 0; i < taskArray0.length; i++) {
        pickupFields[i].name = getValues(`task[${0}][${i}].name`);
        // console.log()
        pickupFields[i].email = getValues(`task[${0}][${i}].email`);
        pickupFields[i].taskDescription = getValues(
          `task[${0}][${i}].description`
        );
        pickupFields[i].phone = getValues(`task[${0}][${i}].phone`);
        pickupFields[i].defaultPhone = splitPhone(
          pickupFields[i].dialCode + getValues(`task[${0}][${i}].phone`)
        );
        pickupFields[i].referenceId = getValues(`task[${0}][${i}].referenceId`);
        pickupFields[i].capacity = getValues(`task[${0}][${i}].capacity`);
        if (taskArray0.length == 1) {
          totalCapacity = Number(taskArray0[i]?.capacity);
        }
        if (taskArray0.length > 1 && taskArray0[i]?.capacity) {
          totalCapacity = totalCapacity + Number(taskArray0[i].capacity);
        }
        if (showServiceTime) {
          pickupFields[i].service_time = getValues(
            `task[${0}][${i}].service_time`
          );
        }
      }
    }
    if (
      (workflow === 0 && activeTab === 1) ||
      (workflow === 1 && activeTab === 0)
    ) {
      let taskArray1: any = deliveryFields;
      for (let i = 0; i < taskArray1.length; i++) {
        deliveryFields[i].name = getValues(`task[${1}][${i}].name`);

        deliveryFields[i].email = getValues(`task[${1}][${i}].email`);
        deliveryFields[i].taskDescription = getValues(
          `task[${1}][${i}].description`
        );
        deliveryFields[i].phone = getValues(`task[${1}][${i}].phone`);
        deliveryFields[i].defaultPhone = splitPhone(
          deliveryFields[i].dialCode + getValues(`task[${1}][${i}].phone`)
        );
        deliveryFields[i].referenceId = getValues(
          `task[${1}][${i}].referenceId`
        );
        deliveryFields[i].referenceId = getValues(
          `task[${1}][${i}].referenceId`
        );
        deliveryFields[i].capacity = getValues(`task[${1}][${i}].capacity`);
        if (showServiceTime) {
          deliveryFields[i].service_time = getValues(
            `task[${1}][${i}].service_time`
          );
        }
      }
    }
    let checkValidation: any;
    if (workflow === 0 && newValue === 2) {
      if (!pickupFields[0]?.phone && !deliveryFields[0].phone) {
        toaster.addToast({
          message: t("Createjob:Please add atleast one task in the job"),
          timeout: 3000,
          type: "error",
        });
        return;
      }
    }
    if (workflow === 1 && newValue === 1) {
      if (!deliveryFields[0].phone) {
        toaster.addToast({
          message: t("Createjob:Please add atleast one task in the job"),
          timeout: 3000,
          type: "error",
        });
        return;
      }
    }
    if (newValue > activeTab) {
      checkValidation = await validationOnTabChange(activeTab, newValue);
    }
    handleEmptyFieldsValidation(activeTab);

    if (checkValidation === false && newValue > activeTab) {
      return;
    }

    if (newValue === 1 && workflow === 0 && deliveryFields.length <= 1) {
      setExpanded(`accordion${0}${1}`);
    } else if (newValue === 0 && workflow === 0 && pickupFields.length <= 1) {
      setExpanded(`accordion${0}${0}`);
    } else if (newValue === 0 && workflow === 1) {
      setExpanded(`accordion${0}${1}`);
    } else {
      setExpanded(``);
    }
    setActiveTab(newValue);
    setLatInputShrink(false);
    setLngInputShrink(false);
  };
  const addAnotherTaskToJob = (taskType: any) => {
    if (taskType === 0) {
      for (let i = 0; i < pickupFields.length; i++) {
        console.log(pickupFields, "Pickup Fields");
        if (
          !getValues(`task[${0}][${i}].phone`) ||
          !pickupFields[i].location ||
          !pickupFields[i].local_datetime
        ) {
          toaster.addToast({
            message: t(
              `Createjob:Please fill required fields in pickup ${i + 1}`
            ),
            timeout: 3000,
            type: "error",
          });

          return;
        }
        if (pickupFields[i].templateFieldList) {
          for (let j = 0; j < pickupFields[i].templateFieldList.length; j++) {
            if (
              pickupFields[i].templateFieldList[j].data_type ==
              TemplateDataTypeEnum.EMAIL
            ) {
              console.log(getValues(`task[${0}][${i}].phone`), "uiuiuiuiiu");
              if (
                !getValues(`task[${0}][${i}].email`) &&
                pickupFields[i].templateFieldList[j].value
              ) {
                toaster.addToast({
                  message: t(
                    `Createjob:Please Add the primary email before adding the email in the Template in pickup ${
                      i + 1
                    }`
                  ),
                  timeout: 4000,
                  type: "error",
                });
                return;
              }
            }
          }
        }
      }
      addTaskToJob(0);
      setExpanded(`accordion${pickupFields.length}${0}`);
    }
    if (taskType === 1) {
      for (let i = 0; i < deliveryFields.length; i++) {
        if (
          !getValues(`task[${1}][${i}].phone`) ||
          !deliveryFields[i].location ||
          !deliveryFields[i].local_datetime
        ) {
          toaster.addToast({
            message: t(
              `Createjob:Please fill required fields in delivery ${i + 1}`
            ),
            timeout: 3000,
            type: "error",
          });
          return;
        }
        if (deliveryFields[i].templateFieldList) {
          for (let j = 0; j < deliveryFields[i].templateFieldList.length; j++) {
            if (
              deliveryFields[i].templateFieldList[j].data_type ==
              TemplateDataTypeEnum.EMAIL
            ) {
              if (
                !getValues(`task[${1}][${i}].email`) &&
                deliveryFields[i].templateFieldList[j].value
              ) {
                toaster.addToast({
                  message: t(
                    `Createjob:Please Add the primary email before adding the email in the Template in delivery ${
                      i + 1
                    }`
                  ),
                  timeout: 4000,
                  type: "error",
                });
                return;
              }
            }
          }
        }
      }

      addTaskToJob(1);
      setExpanded(`accordion${deliveryFields.length}${1}`);
    }
  };
  const checkKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      return;
    }
  };
  const EmptyBackDrop = () => {
    return <div></div>;
  };
  return (
    <Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        // style={{maxWidth:"400px"}}
        // onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <>
            <form
              onSubmit={
                (workflow === 0 && activeTab < 2) ||
                (workflow === 1 && activeTab < 1)
                  ? handleSubmit(handleTabChangeFromButton)
                  : handleSubmit(onSubmit)
              }
              className="CreateJob"
              onKeyDown={checkKeyDown}
            >
              <div className={classes.paper}>
                <div className="CreateJob__form">
                  {/* <h2
                  id="transition-modal-title"
                  className="CreateJob__form__padding"
                >
                  {operation === CreateTaskOperation.EDIT
                    ? t("Common:Edit")
                    : operation === CreateTaskOperation.DUPLICATE
                      ? t("Common:Duplicate")
                      : t("Common:New")}
                  {" " + t("Common:Job")}
                </h2> */}
                  {/* ------------------------CROSS ICON------------------------------- */}
                  <div
                    style={{
                      marginRight: "20px",
                      marginTop: "9px",
                      cursor: "pointer",
                      textAlign: "right",
                    }}
                  >
                    <img
                      onClick={handleClose}
                      src={crossIcon}
                      height={"22px"}
                    />
                  </div>
                  {/* ----------------------------------------------------------------- */}

                  <div className="CreateJob__form__padding CreateJob__form__task-list">
                    <Fragment>
                      <Tabs
                        classes={{
                          root: classes.tabs,
                          indicator: classes.tabsIndicator,
                        }}
                        value={activeTab}
                        style={{ marginTop: "-16px" }}
                        indicatorColor="primary"
                        TabIndicatorProps={{ style: { fontWeight: "bold" } }}
                        // textColor="Black"
                        // variant="fullWidth"
                        // style={{ width: 200, float: 'left' }}
                        onChange={handleTabChange}
                        aria-label="disabled tabs example"
                      >
                        {workflow === 0 && (
                          <Tab
                            // disabled={ ? false : true}
                            label={
                              <span className={classes.tabLabelClass}>
                                {t("Common:Pickup")}{" "}
                              </span>
                            }
                            style={{
                              // marginLeft: "-10px",
                              color: `${themeColor.blackColor}`,
                              display:
                                operation === CreateTaskOperation.CREATE ||
                                operation === CreateTaskOperation.DUPLICATE ||
                                (pickupFields.length > 0 &&
                                  operation === CreateTaskOperation.EDIT)
                                  ? ""
                                  : "none",
                            }}
                            className={
                              activeTab === 0
                                ? classes.activeTab
                                : classes["MuiTab-root"]
                            }
                          />
                        )}
                        {
                          <Tab
                            label={
                              <span className={classes.tabLabelClass}>
                                {workflow === 0
                                  ? t("Createjob:Delivery")
                                  : t("Createjob:Service")}{" "}
                              </span>
                            }
                            style={
                              workflow === 1
                                ? {
                                    // marginLeft: "-10px",
                                    color: `${themeColor.blackColor}`,
                                  }
                                : {
                                    display:
                                      operation ===
                                        CreateTaskOperation.CREATE ||
                                      operation ===
                                        CreateTaskOperation.DUPLICATE ||
                                      (deliveryFields.length > 0 &&
                                        operation === CreateTaskOperation.EDIT)
                                        ? ""
                                        : "none",
                                  }
                            }
                            className={
                              (activeTab === 1 && workflow === 0) ||
                              (activeTab === 0 && workflow === 1)
                                ? classes.activeTab
                                : classes["MuiTab-root"]
                            }
                          />
                        }

                        <Tab
                          label={
                            <span className={classes.tabLabelClass}>
                              {t("Createjob:Assign Agent")}{" "}
                            </span>
                          }
                          className={
                            (activeTab === 2 && workflow === 0) ||
                            (activeTab === 1 && workflow === 1)
                              ? classes.activeTab
                              : classes["MuiTab-root"]
                          }
                        />
                      </Tabs>
                    </Fragment>
                    <div className="CreateJob__form__task-list__leftA">
                      {/* {operation === CreateTaskOperation.CREATE ? (
                    <div>
                      <RadioGroup
                        value={workflow}
                        onChange={(evt: any) => {
                          switchWorkFlow(parseInt(evt.target.value));
                        }}
                        row
                        aria-label="position"
                        name="position"
                      >
                        <div
                          style={{ marginRight: "10px" }}
                          className="CreateJob__form__job-type CreateJob__form__add-first-job-type-heading"
                        // onClick={() => {
                        //   switchWorkFlow(0);
                        // }}
                        >
                          <Radio
                            id="recurring-end-date"
                            color="primary"
                            value={0}
                          />
                          <label
                            htmlFor="recurring-end-date"
                            className={
                              "CreateJob__repeatOn__schedule-criteria-label"
                            }
                          >
                            {t("Common:Pickup & Delivery")}
                          </label>
                        </div>
                        <div
                          className="CreateJob__form__job-type CreateJob__form__add-first-job-type-heading"
                        // onClick={() => {
                        //   switchWorkFlow(1);
                        // }}
                        >
                          <Radio
                            id="no-of-occurrences"
                            name="no-of-occurrences"
                            color="primary"
                            value={1}
                          />
                          <label
                            htmlFor="no-of-occurrences"
                            className={
                              "CreateJob__repeatOn__schedule-criteria-label"
                            }
                          >
                            {t("Common:Services")}
                          </label>
                        </div>
                      </RadioGroup>
                    </div>
                  ) : null} */}

                      {activeTab === 0 &&
                        pickupFields.map((item: any, index) => {
                          return AddTaskToJobArrayInHtmlView(item, index, 0);
                        })}
                      {/* <div
                    style={{ marginBottom: "rem" }}
                    hidden={
                      operation === CreateTaskOperation.EDIT && workflow === 0 && (pickupFields.length > 1 || deliveryFields.length > 1)
                        ? false
                        : true
                    }
                  ></div> */}
                      {workflow === 0 &&
                      (CreateTaskOperation.EDIT !== operation ||
                        ![5, 6, 7].includes(jobDataForEditTask.job_status)) &&
                      activeTab == 0 ? (
                        pickupFields.length < 1 ? (
                          <div
                            className="CreateJob__form__add-first-job-type-heading"
                            onClick={() => {
                              addTaskToJob(0);
                            }}
                          >
                            {t("Common:Pickup")}
                            {/* <AddIcon /> */}
                          </div>
                        ) : deliveryFields.length < 2 ? (
                          <div
                            // className="CreateJob__form__add-another-job-type-heading"
                            style={{
                              display: "flex",
                              margin: "1rem 1rem",
                              color: `${themeColor.primary.main}`,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              addAnotherTaskToJob(0);
                            }}
                          >
                            <AddCircleOutlinedIcon
                              fontSize={"default"}
                              style={{ color: "var(--primary-theme)" }}
                            ></AddCircleOutlinedIcon>
                            <span className="CreateJob__form__add-another-job-type-heading__text">
                              {t("Createjob:Add Another Pickup Point")}{" "}
                            </span>{" "}
                          </div>
                        ) : null
                      ) : null}

                      {((activeTab === 1 && workflow === 0) ||
                        (activeTab === 0 && workflow === 1)) &&
                        deliveryFields.map((item: any, index) => {
                          return AddTaskToJobArrayInHtmlView(item, index, 1);
                        })}
                      {/* <div
                    style={{ margin: "2rem" }}
                    hidden={
                      operation === CreateTaskOperation.EDIT && workflow === 0 ? false : true
                    }
                  ></div> */}
                      {(activeTab === 1 && workflow === 0) ||
                      (activeTab === 0 &&
                        workflow === 1 &&
                        CreateTaskOperation.EDIT !== operation) ? (
                        deliveryFields.length < 1 ? (
                          <div
                            className="CreateJob__form__add-first-job-type-heading"
                            onClick={() => {
                              addTaskToJob(1);
                            }}
                          >
                            {t("Common:Delivery")}
                            {/* <AddIcon /> */}
                          </div>
                        ) : pickupFields.length < 2 &&
                          (CreateTaskOperation.EDIT !== operation ||
                            ![5, 6, 7].includes(
                              jobDataForEditTask.job_status
                            )) &&
                          workflow === 0 ? (
                          <div
                            // className="CreateJob__form__add-another-job-type-heading"
                            style={{
                              display: "flex",
                              margin: "1rem 1rem",
                              color: `${themeColor.primary.main}`,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              addAnotherTaskToJob(1);
                            }}
                          >
                            <AddCircleOutlinedIcon
                              fontSize={"default"}
                              style={{ color: "var(--primary-theme)" }}
                            ></AddCircleOutlinedIcon>
                            <span className="CreateJob__form__add-another-job-type-heading__text">
                              {t("Createjob:Add Another Delivery Point")}{" "}
                            </span>{" "}
                          </div>
                        ) : null
                      ) : null}

                      <div
                        className="CreateJob__assign-agent"
                        hidden={
                          (activeTab === 2 && workflow === 0) ||
                          (activeTab === 1 && workflow === 1)
                            ? false
                            : true
                        }
                      >
                        {((activeTab === 2 && workflow === 0) ||
                          (activeTab === 1 && workflow === 1)) &&
                          (() => {
                            return AssignAgentViewInHtml();
                          })()}
                        {/* {(() => {
                    return CreateRecurringJob();
                  })()} */}
                        {operation !== CreateTaskOperation.EDIT ||
                        (isRecurringEdit &&
                          ((workflow === 0 && activeTab === 2) ||
                            (workflow === 1 && activeTab === 1))) ? (
                          <RecurringSettingsInHtml></RecurringSettingsInHtml>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="CreateJob__form__footer CreateJob__form__padding">
                  <Grid
                    container
                    justify={
                      activeTab > 0 &&
                      (pickupFields.length != 0 ||
                        activeTab > 1 ||
                        (workflow > 0 && activeTab >= 1)) &&
                      activeTab >= 1
                        ? "space-between"
                        : "flex-end"
                    }
                    alignItems={"baseline"}
                  >
                    {activeTab > 0 &&
                      (pickupFields.length != 0 ||
                        activeTab > 1 ||
                        (workflow > 0 && activeTab >= 1)) &&
                      activeTab >= 1 && (
                        // <div className="CreateJob__form__footer CreateJob__form__padding">
                        <Btn
                          type={"button"}
                          className={"CreateJob__form__footer__edit-button"}
                          onClick={
                            (workflow === 0 && activeTab > 0) ||
                            (workflow === 1 && activeTab > 0)
                              ? () => {
                                  handleTabChangeFromButton([], "back");
                                }
                              : () => {}
                          }
                          color={"default"}
                          variant="outlined"
                          btnText={
                            // workflow === 0 && activeTab === 1
                            //   ? t("Common:Edit Pickup Details")
                            //   : workflow === 0 && activeTab === 2
                            //     ? t("Common:Edit Delivery Details")
                            //     : workflow === 1 && activeTab === 1
                            //       ? t("Common:Edit Service Details")
                            //       : ""
                            t("ImportJobs:Back")
                          }
                        />
                        // </div>
                      )}
                    <Btn
                      className={"CreateJob__form__footer__create-button"}
                      type="submit"
                      btnText={
                        operation === CreateTaskOperation.EDIT &&
                        workflow === 0 &&
                        activeTab === 0 &&
                        deliveryFields.length > 0
                          ? t("Common:Edit Delivery")
                          : workflow === 0 &&
                            activeTab === 0 &&
                            deliveryFields.length === 0
                          ? t("Createjob:Assign Agent")
                          : workflow === 0 && activeTab === 0
                          ? // &&
                            // operation === CreateTaskOperation.CREATE
                            t("Common:Add Delivery")
                          : (workflow === 0 && activeTab === 1) ||
                            (workflow === 1 && activeTab === 0)
                          ? t("Createjob:Assign Agent")
                          : operation === CreateTaskOperation.EDIT
                          ? isRecurringEdit
                            ? t("Common:Update Rule")
                            : t("Common:Update Job")
                          : t("Common:Create Job")
                      }
                    />
                  </Grid>{" "}
                </div>
              </div>
            </form>
            <CreateJobInfo
              activeJobStep={activeTab}
              showInfoPopup={showInfoPopup}
              workflow={workflow}
            />
          </>
        </Fade>
      </Modal>
    </Fragment>
  );
});

export default CreateJob;
