import React, { useEffect, useState, Fragment, useRef, Suspense } from "react";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { themeColor } from "../../constants/themeColor";
import classes from "./Header.module.scss";
import headerDropdownClasses from "../../assets/css/HeaderDropdown.module.scss";
import PublishIcon from "@material-ui/icons/Publish";
import * as actions from "../../AppContext/actions/actions";
import clsx from "clsx";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
// import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CachedIcon from "@material-ui/icons/Cached";
import TeamListDropdown from "../../components/TeamListDropdown/TeamListDropdown";
import UserTypeDropdown from "../../components/UserTypeDropdown/UserTypeDropdown";
import ResourceListDropdownForHeader from "../../components/ResourceListDropdownForHeader/ResourceListDropdownForHeader";
import headerDropdownService from "../../services/headerDropdown.service";
import Btn from "../Button/Button";
import { socketToasterMessageSubject } from "../../utils/socket";
import useToastRight from "../ToastRight/hooks/useToastRight";
//import useToast from "../Toast/hooks/useToast";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import useLocalStorage from "../../utils/localStorage";
import { useAppContext } from "../../AppContext/App.context";
import useApiService from "../../services/api.service";
import CommonEndpoints from "../../services/commonApi.service";
import { userPermissionRoles } from "../../constants/userPermissionRoles";
import Input from "../../components/Input/Input";
import DateTimeFormatter from "../../utils/DateFormatter";
// import {MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateTimePickerInput from "../TemplatesCommonMethods/DateTimePickerInput";
import { useForm } from "react-hook-form";
import MomentUtils from "@date-io/moment";
import CalendarTodayIcon from "@material-ui/icons/CalendarTodayOutlined";
import TeamDropdownIcon from "../../assets/images/header/team_dropdown_icon.svg";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Tooltip from "../Tooltip/Tooltip";
import { extensionSubject } from "../../containers/Extensions/ExtensionActionButton";
import DaysListHeader from "../../components/DaysListDropdown/DaysListDropdown";
import { useTranslation } from "react-i18next";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import DateRangePopup from "../DateRangePopup/DatRangePopup";
import DateRangePopupHeader from "../DateRangePopup/DateRangePopupHeader";
import Dropdown from "../Dropdown/Dropdown";
import ChatUserTypeDropdown from "../ChatUserTypeDropdown/ChatUserTypeDropdown";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ReleaseUpdates from "../../containers/Dashboard/ReleaseUpdates";
import CustomerListDropdown from "../customer/CustomerListDropdown";
import StripePaymentTypeDropdown from "../StripePaymentTypeDropdown/StripePaymentTypeDropdown";
import { extensionList } from "../../constants/extensionList";
import GetAppIcon from "@material-ui/icons/GetApp";
import TagListDropdown from "../TagListDropdown/TagListDropdown";
import DateFormatter from "../../utils/DateFormatter";
import TemplateListDropdown from "../TemplateDetail/TemplateListDropdown/TemplateListDropdown";
import { extensionService } from "../../containers/Extensions/Extensions.endpoints";
import ResourceTypeDropdown from "../ResourceTypeDropdown/ResourceTypeDropdown";
import JobTypeDropdown from "../JobTypes/JobTypeDropdown";
import { NLevelApiService } from "../../containers/Settings/NLevelCatalog/Nlevel.endpoints";
import CustomerAppOptionDropdown from "../CustomerAppDropdown/CustomerAppDropdown";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    "&::before": {
      borderBottomColor: "rgba(97, 97, 97, 0.92) !important",
    },
  },
  headerDropdownSelect: {
    marginTop: "0px !important",
    marginLeft: "8px !important",
  },
}));
// import Header from "../Header/Header";
const Header = (props: any) => {
  const { t, i18n } = useTranslation(["Header", "Common", "Wallet"]);
  const [isAdmin, setIsAdmin]: any = useState(true);
  const arrayOfLocation = [
    "app.networkon.io",
    "app.networkon.in",
    "localhost:3000",
    "app-test.networkon.in",
  ];
  const [selectedTeamId, setTeamId] = useState("");
  const [appData, dispatch]: any = useAppContext();
  const location = useLocation();
  const history = useHistory();
  const [highlightedDates, setHighlightedDatesArr]: any = useState([]);
  const toasterRight = useToastRight();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorImportExport, setAnchorImportExport] = React.useState(null);
  const [
    anchorImportExportCustomer,
    setAnchorImportExportCustomer,
  ] = React.useState(null);
  const [anchorImportExportAgent, setAnchorImportExportAgent] = React.useState(
    null
  );
  const loginData: any = useLocalStorage.getItem("loginData");
  const Permission: any = useLocalStorage.getItem("Permission");
  const [selectedChatUserType, setSelectedChatUserType]: any = useState(0);
  const ApiService = useApiService();
  const [profileImage, setProfileImage]: any = useState("");
  const [enpType, setEnpType]: any = useState(0);
  let currentEnDate: any = new Date();
  const [currentMonth, setCurrentMonth]: any = useState();
  const [stripeUserType, setStripeUserType] = useState<any>(null);
  let [roConfigData, setRoConfigData]: any = useState();
  currentEnDate = DateTimeFormatter.changeOnlyFormatInSameTimeZone(
    currentEnDate,
    DateTimeFormatter.getDateFormat()
  );

  const {
    register,
    control,
    handleSubmit,
    reset,
    errors,
    setValue,
  } = useForm();

  const newMinuteInDateForNewJob = new Date(
    new Date().setMinutes(new Date().getMinutes() + 20)
  );
  console.log(newMinuteInDateForNewJob, "dddsds");

  const muiClasses = useStyles();

  const [selectedDate, handleDateChange] = useState(newMinuteInDateForNewJob);
  const [selecteTripDate, setSelectedTripDate] = useState(
    newMinuteInDateForNewJob
  );
  const [showStripPaymentDropdown, setShowStripPaymentDropdown] = useState(
    false
  );

  const onDateChange = (event: any, data: any) => {
    // updateDateFromPicker(data);

    var newdate = event;
    // var dateformatted = DateTimeFormatter.changeOnlyFormatInSameTimeZone(
    //   newdate,
    //   "YYYY-MM-DD"
    // );
    const dropdownData = {
      type: "DateChanged",
      data: { date: newdate },
    };
    headerDropdownService.emit(dropdownData);
  };

  // const onDateRangeChange = (startDate: any, endDate: any) => {
  //   const dropdownData = {
  //     type: "dateRange",
  //     data: { date: { startDate, endDate } },
  //   };
  //   debugger;
  //   headerDropdownService.emit(dropdownData);
  // };

  const showToasterMessage = (socketResponse: any) => {
    toasterRight.addToastRight({
      // message: t(`Header: ${socketResponse.message.toString()}`),
      message: socketResponse.message.toString(),
      timeout: 3000,
      type: "success",
    });
    return;
  };
  const handleSocketResponse = (socketResponse: any) => {
    switch (socketResponse.type) {
      case "toasterMessage":
        showToasterMessage(socketResponse);
        break;
      default:
        break;
    }
  };
  const getRoutesConfig = () => {
    const headers = {
      access_token: appData.loginData.access_token,
    };
    const request: any = {};
    const reqData = {
      reqBody: request,
      headers: headers,
    };
    return ApiService["get"](extensionService.getROconfig(reqData)).then(
      (res: any) => {
        useLocalStorage.setItem("roConfigType", res.data[0].priority);
        setRoConfigData(res.data[0].priority);
      }
    );
  };
  useEffect(() => {
    if (loginData && !loginData.is_loaded) {
      loginData.is_loaded = 1;
      useLocalStorage.setItem("loginData", loginData);
    }
    if (loginData && loginData.is_loaded) {
      getData();
    }
  }, []);
  const getData = async () => {
    await getPermissions(loginData);
    await getActivatedExtension(loginData);
    await checkAdmin();
    if (
      loginData.onboard_step < 7 &&
      loginData.onboard_step &&
      !location.pathname.includes("trial_expire")
    ) {
      getUserProfileDetails(loginData.email);
    }
  };
  useEffect(() => {
    if (location.pathname === "/routes/list") {
      getRoutesConfig();
      getJobDates();
    } else {
      setHighlightedDatesArr([]);
    }
  }, [currentMonth, location.pathname]);
  const getCurrentMonthFirstAndLastDate = () => {
    var date;
    if (currentMonth) {
      date = new Date(currentMonth);
    } else {
      date = new Date();
    }
    const y = date.getFullYear(),
      m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const firstDateDiff =
      firstDay.getDate() -
      firstDay.getDay() +
      (firstDay.getDay() === 0 ? -6 : 0);
    const firstDateOfMonth = new Date(firstDay.setDate(firstDateDiff));
    const convertedFirstDate = DateFormatter.changeOnlyFormatInSameTimeZone(
      firstDateOfMonth,
      "YYYY-MM-DD"
    );
    const lastDay = new Date(y, m + 1, 0);
    const lastDateDiff = lastDay.getDate() - (lastDay.getDay() - 1) + 6;
    const lastDateOfMonth = new Date(lastDay.setDate(lastDateDiff));
    const convertedLastDate = DateFormatter.changeOnlyFormatInSameTimeZone(
      lastDateOfMonth,
      "YYYY-MM-DD"
    );
    return { convertedFirstDate, convertedLastDate };
  };
  const getJobDates = async () => {
    var datesObject = getCurrentMonthFirstAndLastDate();
    const loginData = useLocalStorage.getItem("loginData");
    let header: any = {
      access_token: loginData?.access_token,
    };
    let request: any = {
      start_date: datesObject.convertedFirstDate,
      end_date: datesObject.convertedLastDate,
    };
    let reqData = {
      reqBody: request,
      headers: header,
    };
    await ApiService.get(CommonEndpoints.getJobsDates(reqData))
      .then((response: any) => {
        setHighlightedDatesArr(response.data);
      })
      .catch((err: any) => {});
    return true;
  };

  // useEffect(() => {
  //   console.log(highlightedDates);
  //   debugger;
  // }, [highlightedDates]);

  useEffect(() => {
    let headerSubscription = headerDropdownService.subscribe(
      dropdownUpdatedFromHeader
    );
    let socketSubscription: any = socketToasterMessageSubject.subscribe(
      (res: any) => {
        handleSocketResponse(res);
      }
    );

    // let headerSubscriptionimage = headerDropdownService.subscribe(eventFromService);
    // console.log(headerSubscriptionimage);

    return () => {
      headerSubscription.unsubscribe();
      socketSubscription.unsubscribe();
      //  headerSubscriptionimage.unsubscribe();
    };
  }, []);
  const checkAdmin = () => {
    if (appData.loginData.user_id === appData.loginData.admin_id) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  };
  const getUserProfileDetails = async (userEmail: any) => {
    let headers = {
      access_token: loginData.access_token,
    };
    let qureyParams = {
      email: userEmail,
    };
    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    const res: any = await ApiService.get(
      CommonEndpoints.getUserProfileDetails(reqData)
    );

    const profileDetail: any = {
      ...loginData,
      onboard_step: res.data?.[0]?.onboard_step,
    };
    useLocalStorage.setItem("loginData", profileDetail);
    dispatch(actions.onLoginResponse(profileDetail));
    return true;
  };
  useEffect(() => {}, [Permission]);
  const getPermissions = async (loginData: any) => {
    let headers = {
      access_token: loginData.access_token,
    };
    let qureyParams = {
      role_id: loginData.role_id,
      limit: 1,
      skip: 0,
    };
    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    const res: any = await ApiService.get(
      CommonEndpoints.getRolesList(reqData)
    );
    const roleDetail: any = res.data.data[0];
    var TabPermission: any = {};
    let modpermsvalues: any = Object.values(roleDetail.modperms);
    if (modpermsvalues && modpermsvalues.length > 0) {
      modpermsvalues.map((mode: any) => {
        let moduleName: any =
          mode.module_alias?.charAt(0).toUpperCase() +
          mode.module_alias?.slice(1).replace(/_/g, "");
        for (var key in mode.permission) {
          TabPermission[`${userPermissionRoles[key].name}_${moduleName}`] =
            mode.permission[key].access_type;
        }
      });
    }
    useLocalStorage.setItem("Permission", TabPermission);
    const dropdownData = {
      type: "permissionData",
      data: TabPermission,
    };
    headerDropdownService.emit(dropdownData);
    return true;
  };
  const getActivatedExtension = async (loginData: any) => {
    let header: any = {
      access_token: loginData.access_token,
    };
    let request: any = {
      limit: 1000,
      skip: 0,
      user_id: loginData.user_id,
    };
    let reqData = {
      reqBody: request,
      headers: header,
    };
    let data: any = [];
    await ApiService.get(CommonEndpoints.activatedExtensionList(reqData))
      .then(function (response: any) {
        response.data.map((item: any) => {
          data.push(item.extension_id);
        });

        localStorage.setItem("activatedExtension", JSON.stringify(data));
        data.map((d: any) => {
          if (d == extensionList.agentReferral.id) {
            setShowStripPaymentDropdown(true);
          }
        });
      })
      .catch((err: any) => {});
    return true;
  };

  useEffect(() => {
    let extensionSubscription: any = extensionSubject.subscribe((res: any) => {
      if (
        res.action === "activated" &&
        res.id === extensionList.agentReferral.id
      ) {
        setShowStripPaymentDropdown(true);
      } else if (
        res.action === "deactivated" &&
        res.id === extensionList.agentReferral.id
      ) {
        setShowStripPaymentDropdown(false);
      }
    });
    return () => {
      extensionSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {}, [showStripPaymentDropdown]);

  const ENPDropdown: any = [
    {
      value: t("Common:Earning"),
    },
    {
      value: t("Common:Pricing"),
    },
  ];
  const [enpDropdownValue, setEnpDropdownValue]: any = useState(
    window.location.href.indexOf("pricing") > -1
      ? ENPDropdown[1]["value"]
      : ENPDropdown[0]["value"]
  );

  const dropdownUpdatedFromHeader = (dropdownData: any) => {
    switch (dropdownData.type) {
      case "teamList":
        teamListDropdownUpdated(dropdownData.data);
        break;
      case "resetTeamListForHeader":
        teamListDropdownUpdated(dropdownData.data);
        break;
      case "ProfileImage":
        setProfileImage(dropdownData.value);
        break;
      case "stripePaymentType":
        setStripeUserType(dropdownData.data);
        break;
      case "chatUserType":
        setSelectedChatUserType(dropdownData.data.chatUserType);
        break;
      case "enp_type":
        setEnpType(dropdownData.value ? 1 : 0);
      case "isRouteChange":
        getJobDates();
      default:
        break;
    }

    switch (dropdownData.action) {
      case "activated" && dropdownData.data == 19:
        setShowStripPaymentDropdown(true);
        break;

      case "deactivated" && dropdownData.data == 19:
        setShowStripPaymentDropdown(false);
        break;

      default:
        break;
    }
  };

  const triggerActionFromHeader = (action: string) => {
    const dropdownData = {
      type: action,
      data: { clicked: true },
    };
    headerDropdownService.emit(dropdownData);
    //headerDropdownService.setData(dropdownData);
  };

  const teamListDropdownUpdated = (data: any) => {
    setTeamId(data.teamId);
  };

  const logoutFromDashboard = () => {
    //localStorage.clear();
    dispatch(actions.onLogout());
    window.location.href = "/login";
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    handleImportExportClose();
  };

  const handleImportExportClick = (event: any) => {
    setAnchorImportExport(event.currentTarget);
  };

  const handleImportExportClose = () => {
    setAnchorImportExport(null);
  };

  const handleImportExportCustomerClick = (event: any) => {
    setAnchorImportExportCustomer(event.currentTarget);
  };

  const handleImportExportCustomerClose = () => {
    setAnchorImportExportCustomer(null);
  };

  const handleImportExportAgentClick = (event: any) => {
    setAnchorImportExportAgent(event.currentTarget);
  };

  const handleImportExportAgentClose = () => {
    setAnchorImportExportAgent(null);
  };

  const handleClickOpen = () => {
    history.push("/editprofile");
    setAnchorEl(null);
  };
  // location.pathname.includes("/catalog")
  //   && GetCustomerAppSettings()
  const updateDateFromPicker = (data: any) => {
    handleDateChange(data);
    //  let str=data._d;
    //  let newdate=DateTimeFormatter.changeOnlyFormatInSameTimeZone(str,'YYYY/MM/DD');
    //  console.log(newdate);
  };
  const [selectedTab, setSelectedTab]: any = useState();

  const handleDropdownChange = (dropdownValue: number) => {
    console.log("droooop", dropdownValue);
    setSelectedTab(dropdownValue);
    const dropdownData = {
      type: "resourceDropdownChange",
      data: { date: dropdownValue },
    };
    headerDropdownService.emit(dropdownData);

    switch (dropdownValue) {
      case 1:
        history.push("/resources/list");

        break;
      case 2:
        history.push("/vehicle/list");

        break;
      case 3:
        history.push("/asset/list");

        break;
      default:
        break;
    }
  };

  const handleButtonClick = () => {
    switch (location.pathname) {
      case "/resources/list":
        history.push("/resources/add");
        break;
      case "/vehicle/list":
        history.push("/vehicle/add");
        break;
      case "/asset/list":
        history.push("/asset/add");
        break;
      default:
        break;
    }
  };

  const handleButtonName = () => {
    switch (location.pathname) {
      case "/resources/list":
        return t("Header:Add Agent");
      case "/vehicle/list":
        return t("Resourcelist:Add Vehicle");
      case "/asset/list":
        return "Add Asset";
      default:
        return "";
    }
  };

  return (
    <Fragment>
      <div className={classes["header"]}>
        <div className={classes["header__flex"]}>
          <div className={classes["header__flex__left-side"]}>
            {location.pathname.includes("extensions/payments/history") ? (
              <>
                <TagListDropdown
                  default={t("Header:All Tags")}
                ></TagListDropdown>
                <div
                  className={classes.pickerIcon}
                  style={{ marginLeft: "14px" }}
                >
                  <DateRangePopupHeader />
                </div>
              </>
            ) : (
              ""
            )}
            {location.pathname.includes("extensions/agentIncentive/history") ? (
              <>
                <DateRangePopupHeader maxRange={30} />
                <div
                  className={classes.pickerIcon}
                  style={{ marginLeft: "14px" }}
                >
                  <TeamListDropdown
                    default={t("Header:All Teams")}
                  ></TeamListDropdown>
                </div>
              </>
            ) : (
              ""
            )}
            {location.pathname.includes("extensions/agentwallet/history") ? (
              <>
                <DateRangePopupHeader maxRange={30} />
                <div
                  className={classes.pickerIcon}
                  style={{ marginLeft: "14px" }}
                ></div>
              </>
            ) : (
              ""
            )}
            {location.pathname.includes("/extensions/stripe/history") &&
            Permission &&
            Permission["Read_Teams"] ? (
              <div
                style={
                  !showStripPaymentDropdown
                    ? { display: "none" }
                    : { marginLeft: 0, marginRight: 30 }
                }
              >
                <StripePaymentTypeDropdown />
              </div>
            ) : (
              ""
            )}
            <div>
              {(location.pathname.includes("/dashboard") ||
                location.pathname.includes("/scheduler") ||
                location.pathname.includes("/settings/verificationReport")) &&
              Permission &&
              Permission["Read_Teams"] ? (
                <TeamListDropdown
                  default={t("Header:All Teams")}
                ></TeamListDropdown>
              ) : (
                ""
              )}
              {location.pathname === "/settings/resourceschedule" &&
              Permission &&
              Permission["Read_Teams"] ? (
                <TeamListDropdown
                  default={t("Header:All Teams")}
                ></TeamListDropdown>
              ) : (
                ""
              )}
              {location.pathname.includes("/resources/list") ||
              location.pathname.includes("/vehicle/list") ||
              location.pathname.includes("/asset/list") ? (
                <>
                  <ResourceTypeDropdown></ResourceTypeDropdown>
                </>
              ) : (
                ""
              )}
              {location.pathname === "/jobs/list" ||
              (location.pathname === "/jobs/list/byreferenceid" &&
                Permission) ? (
                <TemplateListDropdown />
              ) : (
                ""
              )}
              {location.pathname.includes("/routes/list") &&
              Permission &&
              Permission["Read_Teams"] ? (
                <TeamListDropdown
                  default={t("Header:All Teams")}
                ></TeamListDropdown>
              ) : (
                ""
              )}
              {location.pathname === "/analytics/overview" &&
              Permission &&
              Permission["Read_Teams"] ? (
                <TeamListDropdown
                  default={t("Header:All Teams")}
                ></TeamListDropdown>
              ) : (
                ""
              )}
              {location.pathname === "/extensions/agentreferral" &&
              Permission &&
              Permission["Read_Teams"] ? (
                <TeamListDropdown
                  default={t("Header:All Teams")}
                ></TeamListDropdown>
              ) : (
                ""
              )}
              {location.pathname.includes("/analytics/job") &&
              Permission &&
              Permission["Read_Teams"] ? (
                <TeamListDropdown
                  default={t("Header:All Teams")}
                ></TeamListDropdown>
              ) : (
                ""
              )}

              {/* --------roberto-analytics--------- */}

              {location.pathname.includes("/analytics/report") &&
              Permission &&
              Permission["Read_Teams"] ? (
                <TeamListDropdown
                  default={t("Header:All Teams")}
                ></TeamListDropdown>
              ) : (
                ""
              )}

              {/* --------------------------------------- */}

              {location.pathname.includes("/analytics/route-analytics") &&
              Permission &&
              Permission["Read_Teams"] ? (
                <TeamListDropdown
                  default={t("Header:All Teams")}
                ></TeamListDropdown>
              ) : (
                ""
              )}
              {location.pathname.includes("/analytics/merchantAnalytics") ? (
                <CustomerListDropdown default={t("Header:All Customers")} />
              ) : (
                ""
              )}

              {location.pathname.includes("/extensions/stripe/history") &&
              Permission &&
              Permission["Read_Teams"] ? (
                <TeamListDropdown
                  default={t("Header:All Teams")}
                  stripeUserType={stripeUserType}
                ></TeamListDropdown>
              ) : (
                ""
              )}
              {location.pathname === "/settings/resourceapp" &&
              Permission &&
              Permission["Read_Teams"] ? (
                <TeamListDropdown
                  default={t("Header:All Teams")}
                ></TeamListDropdown>
              ) : (
                ""
              )}
              {location.pathname === "/analytics/resource" &&
              Permission &&
              Permission["Read_Teams"] ? (
                <TeamListDropdown
                  default={t("Header:All Teams")}
                ></TeamListDropdown>
              ) : (
                ""
              )}

              {location.pathname.includes("/analytics/customer") &&
              Permission &&
              Permission["Read_Teams"] ? (
                <TeamListDropdown
                  default={t("Header:All Teams")}
                ></TeamListDropdown>
              ) : (
                ""
              )}
            </div>
            {location.pathname.includes("/analytics/customer") &&
              Permission &&
              Permission["Read_Teams"] ? (<div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "20px",
              }}
            >
              
                <JobTypeDropdown default={t("Header:All")}></JobTypeDropdown>
             
            </div> ) : (
                ""
              )}
            <div>
              {location.pathname == "/settings/roles" ? (
                <UserTypeDropdown
                  default={t("Header:All User Types")}
                ></UserTypeDropdown>
              ) : (
                ""
              )}
              {location.pathname == "/settings/catalog" ? (
                <CustomerAppOptionDropdown />
              ) : (
                ""
              )}
              {location.pathname == "/chat" ? (
                <ChatUserTypeDropdown
                  default={t("Header:All User Types")}
                ></ChatUserTypeDropdown>
              ) : (
                ""
              )}
            </div>
            {/* <div>
              {location.pathname == "/settings/autoallocation" &&
              Permission &&
              Permission["Read_Teams"] ? (
                <TeamListDropdown
                  default={t("Header:Default")}
                ></TeamListDropdown>
              ) : (
                ""
              )}
            </div> */}
            <div
              className={clsx(
                classes["header__flex__item-spacing"],
                classes["dropdownOuter"]
              )}
            >
              {location.pathname.includes("/settings/verificationReport") &&
              Permission &&
              Permission["Read_Resources"] ? (
                // <ResourceListDropdownForHeader  inputProps={{ 'aria-label': 'naked' }} showAllResource={true} teamId={selectedTeamId} showIcon={true} variant={"standard"} location="header"></ResourceListDropdownForHeader>:null
                <ResourceListDropdownForHeader
                  teamId={selectedTeamId}
                ></ResourceListDropdownForHeader>
              ) : null}
              {location.pathname.includes("/dashboard") &&
              Permission &&
              Permission["Read_Resources"] ? (
                // <ResourceListDropdownForHeader  inputProps={{ 'aria-label': 'naked' }} showAllResource={true} teamId={selectedTeamId} showIcon={true} variant={"standard"} location="header"></ResourceListDropdownForHeader>:null
                <ResourceListDropdownForHeader
                  teamId={selectedTeamId}
                ></ResourceListDropdownForHeader>
              ) : null}

              {location.pathname.includes("/analytics/job") &&
              Permission &&
              Permission["Read_Teams"] ? (
                <ResourceListDropdownForHeader
                  teamId={selectedTeamId}
                ></ResourceListDropdownForHeader>
              ) : (
                ""
              )}
              {location.pathname.includes("/analytics/resource") &&
              Permission &&
              Permission["Read_Teams"] ? (
                <ResourceListDropdownForHeader
                  teamId={selectedTeamId}
                ></ResourceListDropdownForHeader>
              ) : (
                ""
              )}
            </div>
            {location.pathname === "/analytics/job" ? (
              <div
                className={clsx(
                  classes.pickerIcon,
                  classes["header__flex__item-spacing"]
                )}
              >
                <DateRangePopupHeader maxRange={30} startDate={6} />
              </div>
            ) : (
              ""
            )}
            {location.pathname === "/analytics/eathappynow/report" ? (
              <div
                className={clsx(
                  classes.pickerIcon,
                  classes["header__flex__item-spacing"]
                )}
              >
                <DateRangePopupHeader maxRange={30} startDate={6} />
              </div>
            ) : (
              ""
            )}

            {/* -----------------/roberto-analytics-------------------- */}

            {location.pathname === "/analytics/report" ? (
              <div
                className={clsx(
                  classes.pickerIcon,
                  classes["header__flex__item-spacing"]
                )}
              >
                <DateRangePopupHeader maxRange="30" startRange={true} />
              </div>
            ) : (
              ""
            )}

            {/* --------------------------------------------------- */}
            {location.pathname === "/analytics/route-analytics" ? (
              <div
                className={clsx(
                  classes.pickerIcon,
                  classes["header__flex__item-spacing"]
                )}
              >
                <DateRangePopupHeader />
              </div>
            ) : (
              ""
            )}
            {location.pathname === "/analytics/merchantAnalytics" ? (
              <div
                className={clsx(
                  classes.pickerIcon,
                  classes["header__flex__item-spacing"]
                )}
              >
                <DateRangePopupHeader maxRange={31} />
              </div>
            ) : (
              ""
            )}
            {location.pathname === "/analytics/tripAnalytics" ? (
              <div
                className={clsx(
                  classes.pickerIcon,
                  classes["header__flex__item-spacing"]
                )}
              >
                <DateRangePopupHeader
                  //  value={selecteTripDate}
                  // highlightDates={highlightedDates}
                  trip={true}
                  maxRange="30"
                  startRange={true}
                  // monthChange={(data: any) => {
                  //   setCurrentMonth(data);
                  //   // getJobDates();
                  // }}
                  // onChange={(event: any, data: any) => {
                  //   onDateChange(event, data);
                  // }}
                  // calenderOpen={(willApiHit: any) => {
                  //   if (willApiHit) {
                  //     setCurrentMonth("");
                  //   }
                  // }}
                />
              </div>
            ) : (
              ""
            )}
            {location.pathname === "/chat" ? (
              <TeamListDropdown
                default={t("Header:Default")}
                chatUserType={selectedChatUserType}
              ></TeamListDropdown>
            ) : (
              ""
            )}
            {location.pathname === "/extensions/agentreferral" ? (
              <div className={classes.pickerIcon}>
                <DateRangePopupHeader />
              </div>
            ) : (
              ""
            )}
            {location.pathname === "/analytics/customer" ? (
              <div className={classes.pickerIcon}>
                <DateRangePopupHeader />
              </div>
            ) : (
              ""
            )}
            {location.pathname === "/routes/list" && roConfigData == 1 ? (
              <>
                <div
                  className={clsx(
                    classes["pickerIcon"],
                    headerDropdownClasses["dropdownOuter"]
                  )}
                >
                  <CalendarTodayIcon
                    className={classes["dropdown-pre-icon"]}
                    //style={{ color: themeColor.iconsColor }}
                  />
                  <DateTimePickerInput
                    className={"without-border"}
                    type={2}
                    highlightDates={highlightedDates}
                    inputVariant="standard"
                    name="datePicker"
                    value={selectedDate}
                    monthChange={(data: any) => {
                      setCurrentMonth(data);
                    }}
                    isMonthChange={true}
                    onChange={(event: any, data: any) => {
                      onDateChange(event, data);
                    }}
                    calenderOpen={(willApiHit: any) => {
                      if (willApiHit) {
                        setCurrentMonth("");
                        // getJobDates();
                      }
                    }}
                  ></DateTimePickerInput>
                </div>
              </>
            ) : (
              ""
            )}
            {location.pathname === "/routes/list" && roConfigData == 0 ? (
              <>
                <DateRangePopupHeader
                  value={selectedDate}
                  highlightDates={highlightedDates}
                  maxRange={31}
                  monthChange={(data: any) => {
                    setCurrentMonth(data);
                    // getJobDates();
                  }}
                  onChange={(event: any, data: any) => {
                    onDateChange(event, data);
                  }}
                  calenderOpen={(willApiHit: any) => {
                    if (willApiHit) {
                      setCurrentMonth("");
                    }
                  }}
                />
              </>
            ) : (
              ""
            )}
            {location.pathname.includes("/extensions/stripe/history") &&
            Permission &&
            Permission["Read_Teams"] ? (
              <div
                className={clsx(
                  classes.pickerIcon,
                  classes["header__flex__item-spacing"]
                )}
                style={{ marginLeft: 13 }}
              >
                <DateRangePopupHeader
                  maxRange={"31"}
                  stripeUserType={stripeUserType}
                />
              </div>
            ) : (
              ""
            )}

            {location.pathname === "/settings/resourceschedule" ? (
              <div
                className={clsx(
                  classes["pickerIcon"],
                  headerDropdownClasses["dropdownOuter"]
                )}
              >
                <CalendarTodayIcon
                  className={classes["dropdown-pre-icon"]}
                  //style={{ color: themeColor.iconsColor }}
                />
                <DateTimePickerInput
                  className={"without-border"}
                  type={2}
                  inputVariant="standard"
                  name="datePicker"
                  value={selectedDate}
                  onChange={(event: any, data: any) => {
                    onDateChange(event, data);
                  }}
                />
              </div>
            ) : (
              ""
            )}
            {location.pathname === "/settings/verificationReport" ? (
              <div style={{ marginLeft: "20px" }}>
                <DateRangePopupHeader maxRange={30} />
              </div>
            ) : (
              ""
            )}
            {location.pathname === "/analytics/overview" ? (
              <div
                style={{ color: "black" }}
                className={clsx(
                  classes["pickerIcon"],
                  headerDropdownClasses["dropdownOuter"]
                )}
              >
                {currentEnDate}
              </div>
            ) : (
              ""
            )}
            {/* vishal */}
            {location.pathname.includes("/dashboard") ? (
              <div className={clsx(classes["header__flex__item-spacing"])}>
                <DateRangePopupHeader maxRange={30} startDate={0} />
              </div>
            ) : (
              ""
            )}

            {location.pathname === "/analytics/resource" ? (
              <div
                className={clsx(
                  classes["header__flex__item-spacing"],
                  headerDropdownClasses["dropdownOuter"]
                )}
              >
                <div className={classes.pickerIcon}>
                  <CalendarTodayIcon
                    style={{ paddingBottom: "0px" }}
                    className={classes["dropdown-pre-icon"]}
                    //style={{ color: themeColor.iconsColor }}
                  />

                  <DateTimePickerInput
                    className={"without-border"}
                    type={2}
                    inputVariant="standard"
                    name="datePicker"
                    value={selectedDate}
                    onChange={(event: any, data: any) => {
                      onDateChange(event, data);
                    }}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className={classes["header__notification-corner__image"]}>
            {/* <img
              className={
                classes["header__notification-corner__image__image-cover"]
              }
              src={BellIcon}
              alt="notifation bell icon"
            /> */}
            {location.pathname.includes("/resources/list") ||
            location.pathname.includes("/vehicle/list") ||
            (location.pathname.includes("/asset/list") &&
              Permission &&
              Permission["Create_Resources"]) ? (
              <>
                <Btn
                  className={classes["header__btn"]}
                  onClick={() => handleButtonClick()}
                  type={"button"}
                  startIcon={<AddCircleRoundedIcon />}
                  btnText={handleButtonName()}
                ></Btn>
              </>
            ) : (
              ""
            )}
            {location.pathname === "/resources/list" &&
            Permission &&
            Permission["Create_Resources"] ? (
              <>
                {/* <div>
                <Dropdown
                    required={true}
                    labelName={t("Common:helloooo")}
                    onChange={(event:any) => handleDropdownChange(event.target.value)}
                    selectedValue={selectedTab}
                    itemList={AgentDropdownList}
                    uniqueId="id"
                    label="name"
                  ></Dropdown>
                </div> */}

                {/* <Btn
                  className={classes["header__btn"]}
                  onClick={() => {
                    history.push("/resources/add");
                  }}
                  type={"button"}
                  startIcon={<AddCircleRoundedIcon />}
                  btnText={t("Header:Add Agent")}
                ></Btn> */}
                <span>
                  <Tooltip
                    hideOnClick={true}
                    content={"Import/Export Agents"}
                    direction={"bottom"}
                    style={{
                      backgroundColor: "rgba(97, 97, 97, 0.92)",
                      marginBottom: "-8px",
                      left: "65%",
                    }}
                    wrapperStyle={{
                      width: "-webkit-fill-available",
                    }}
                    className={muiClasses.tooltip}
                  >
                    {/* <GetAppIcon
                      onClick={() => triggerActionFromHeader("exportAgents")}
                      className={classes["header__logout-icon"]}
                      style={{ color: themeColor.iconsColor }}
                    /> */}
                    <ImportExportIcon
                      onClick={(event: any) => {
                        handleImportExportAgentClick(event);
                      }}
                      className={classes["header__logout-icon"]}
                      style={{ color: themeColor.iconsColor }}
                    />
                  </Tooltip>
                </span>
                <span>
                  <Tooltip
                    content={"Refresh"}
                    direction={"bottom"}
                    style={{
                      backgroundColor: "rgba(97, 97, 97, 0.92)",
                      marginBottom: "-8px",
                      left: "65%",
                    }}
                    wrapperStyle={{
                      width: "-webkit-fill-available",
                    }}
                    className={muiClasses.tooltip}
                  >
                    <CachedIcon
                      onClick={() =>
                        triggerActionFromHeader("refreshResourceList")
                      }
                      className={classes["header__logout-icon"]}
                      style={{ color: themeColor.iconsColor }}
                    />
                  </Tooltip>
                </span>
              </>
            ) : (
              ""
            )}

            {location.pathname === "/customers/list" &&
            Permission &&
            Permission["Create_Customers"] ? (
              <>
                <Btn
                  className={classes["header__btn"]}
                  onClick={() => {
                    triggerActionFromHeader("clickedOnAddCustomer");
                  }}
                  type={"button"}
                  startIcon={<AddCircleRoundedIcon />}
                  btnText={t("Header:Add Customer")}
                ></Btn>

                <span>
                  <Tooltip
                    hideOnClick={true}
                    content={`Import/Export`}
                    direction={"bottom"}
                    style={{
                      backgroundColor: "rgba(97, 97, 97, 0.92)",
                      marginBottom: "-8px",
                      left: "65%",
                    }}
                    wrapperStyle={{
                      width: "-webkit-fill-available",
                    }}
                    className={muiClasses.tooltip}
                  >
                    <ImportExportIcon
                      onClick={(event: any) => {
                        handleImportExportCustomerClick(event);
                      }}
                      className={classes["header__logout-icon"]}
                      style={{ color: themeColor.iconsColor }}
                    />
                  </Tooltip>
                </span>
              </>
            ) : (
              " "
            )}
            {location.pathname === "/customers/list" &&
            Permission &&
            Permission["Read_Customers"] ? (
              <span>
                <Tooltip
                  content={"Refresh"}
                  direction={"bottom"}
                  style={{
                    backgroundColor: "rgba(97, 97, 97, 0.92)",
                    marginBottom: "-8px",
                    left: "65%",
                  }}
                  wrapperStyle={{
                    width: "-webkit-fill-available",
                  }}
                  className={muiClasses.tooltip}
                >
                  <CachedIcon
                    onClick={() =>
                      triggerActionFromHeader("refreshCustomerList")
                    }
                    className={classes["header__logout-icon"]}
                    style={{ color: themeColor.iconsColor }}
                  />
                </Tooltip>
              </span>
            ) : (
              <></>
            )}
            {location.pathname.includes("extensions/agentwallet/history") ? (
              <>
                <Btn
                  className={classes.header__btn}
                  type={"button"}
                  btnText={t("Header:Add Transaction")}
                  onClick={() => {
                    triggerActionFromHeader("clickedOnAddTransaction");
                  }}
                >
                  {" "}
                </Btn>
              </>
            ) : (
              ""
            )}
            {(location.pathname === "/jobs/list" ||
              location.pathname === "/jobs/list/byreferenceid" ||
              location.pathname.includes("/dashboard") ||
              location.pathname === "/scheduler/") &&
            Permission &&
            Permission["Create_Jobs"] ? (
              <>
                <Btn
                  className={classes["header__buttonRadius"]}
                  onClick={() => {
                    triggerActionFromHeader("clickedOnCreateTask");
                  }}
                  type={"button"}
                  startIcon={<AddCircleRoundedIcon />}
                  btnText={t("Header:New Job")}
                ></Btn>

                {Permission &&
                (Permission["Create_Jobs"] || Permission["Read_Jobs"]) &&
                !location.pathname.includes("/dashboard") &&
                location.pathname !== "/scheduler/" ? (
                  <span>
                    <Tooltip
                      hideOnClick={true}
                      content={`${
                        Permission["Create_Jobs"] && Permission["Read_Jobs"]
                          ? "Import/Export"
                          : Permission["Read_Jobs"]
                          ? "Export"
                          : Permission["Create_Jobs"]
                          ? "Import"
                          : ""
                      }`}
                      direction={"bottom"}
                      style={{
                        backgroundColor: "rgba(97, 97, 97, 0.92)",
                        marginBottom: "-8px",
                        left: "65%",
                      }}
                      wrapperStyle={{
                        width: "-webkit-fill-available",
                      }}
                      className={muiClasses.tooltip}
                    >
                      <ImportExportIcon
                        onClick={(event: any) => {
                          handleImportExportClick(event);
                        }}
                        className={classes["header__logout-icon"]}
                        style={{ color: themeColor.iconsColor }}
                      />
                    </Tooltip>
                  </span>
                ) : //

                null}
              </>
            ) : (
              ""
            )}
            {arrayOfLocation.includes(window.location.host) ? (
              location.pathname.includes("/dashboard") ? (
                <ReleaseUpdates />
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {(location.pathname === "/jobs/list" ||
              location.pathname === "/jobs/list/byreferenceid" ||
              location.pathname.includes("/dashboard") ||
              location.pathname === "/scheduler/") &&
            Permission &&
            Permission["Read_Jobs"] ? (
              <span>
                <Tooltip
                  content={"Refresh"}
                  direction={"bottom"}
                  style={{
                    backgroundColor: "rgba(97, 97, 97, 0.92)",
                    marginBottom: "-8px",
                    left: "65%",
                  }}
                  wrapperStyle={{
                    width: "-webkit-fill-available",
                  }}
                  className={muiClasses.tooltip}
                >
                  <CachedIcon
                    onClick={() => {
                      triggerActionFromHeader(
                        location.pathname !== "/scheduler/"
                          ? "refreshTaskList"
                          : "fetchJobs"
                      );
                    }}
                    className={classes["header__logout-icon"]}
                    style={{ color: themeColor.iconsColor }}
                  />
                </Tooltip>
              </span>
            ) : (
              ""
            )}

            {location.pathname === "/settings/teams" &&
            Permission &&
            Permission["Create_Teams"] ? (
              <Btn
                className={classes["header__btn"]}
                onClick={() => {
                  triggerActionFromHeader("clickedOnAddTeam");
                }}
                startIcon={<AddCircleRoundedIcon />}
                type={"button"}
                btnText={t("Header:Add Team")}
              ></Btn>
            ) : (
              ""
            )}
            {location.pathname === "/extensions/agentIncentive/rules" &&
            Permission ? (
              <Btn
                className={classes["header__btn"]}
                onClick={() => {
                  // triggerActionFromHeader("clickedOnNewAgentRule");
                  history.push("/extensions/agentIncentives/add");
                }}
                startIcon={<AddCircleRoundedIcon />}
                type={"button"}
                btnText={t("Header:Add New Rule")}
              ></Btn>
            ) : (
              ""
            )}
            {location.pathname === "/extensions/subscription/plans" ? (
              <Btn
                className={classes["header__btn"]}
                onClick={() => {
                  triggerActionFromHeader("clickOnAddPlan");
                }}
                startIcon={<AddCircleRoundedIcon />}
                type={"button"}
                btnText={t("Header:New Plan")}
              ></Btn>
            ) : (
              ""
            )}
            {location.pathname === "/extensions/promos" &&
            Permission &&
            Permission["Create_Teams"] ? (
              <Btn
                className={classes["header__btn"]}
                onClick={() => {
                  triggerActionFromHeader("clickedOnAddPromo");
                }}
                startIcon={<AddCircleRoundedIcon />}
                type={"button"}
                btnText={t("Header:Add Promo")}
              ></Btn>
            ) : (
              ""
            )}
            {location.pathname === "/settings/supervisors" &&
            Permission &&
            Permission["Create_Supervisors"] ? (
              <Btn
                className={classes["header__btn"]}
                onClick={() => {
                  triggerActionFromHeader("handleOpenClick");
                }}
                type={"button"}
                startIcon={<AddCircleRoundedIcon />}
                btnText={t("Header:Add Supervisor")}
              ></Btn>
            ) : (
              ""
            )}
            {Permission["Update_Availability"] &&
            location.pathname === "/settings/resourceschedule" ? (
              <Btn
                className={classes["header__btn"]}
                onClick={() => {
                  history.push("/settings/resourceschedule/add");
                }}
                type={"button"}
                startIcon={<AddCircleRoundedIcon />}
                btnText={t("Header:Add Schedule")}
              ></Btn>
            ) : (
              ""
            )}
            {location.pathname === "/settings/roles" &&
            Permission &&
            Permission["Create_Roles"] ? (
              <Btn
                className={classes["header__btn"]}
                onClick={() => {
                  history.push("/settings/role/add");
                }}
                type={"button"}
                startIcon={<AddCircleRoundedIcon />}
                btnText={t("Header:Add Role")}
              ></Btn>
            ) : (
              ""
            )}
            {location.pathname === "/settings/geofence" &&
            Permission &&
            Permission["Create_Geofence"] ? (
              <Btn
                className={classes["header__btn"]}
                onClick={() => {
                  history.push("/settings/geofence/add");
                }}
                type={"button"}
                startIcon={<AddCircleRoundedIcon />}
                btnText={t("Header:Add Geofence")}
              ></Btn>
            ) : (
              ""
            )}
            {location.pathname === "/settings/geofence" ? (
              <BackupOutlinedIcon
                className={classes["header__logout-icon"]}
                onClick={() => {
                  triggerActionFromHeader("exportGeofence");
                }}
                style={{ color: themeColor.iconsColor }}
              ></BackupOutlinedIcon>
            ) : (
              ""
            )}
            {location.pathname.includes("/settings/templates/default") ||
            (location.pathname.includes("/settings/templates/custom") &&
              Permission &&
              Permission["Create_Templates"]) ? (
              <>
                <Btn
                  className={classes["header__btn"]}
                  onClick={() => {
                    history.push("/settings/templates/add/");
                  }}
                  type={"button"}
                  startIcon={<AddCircleRoundedIcon />}
                  btnText={t("Header:Add Template")}
                ></Btn>
              </>
            ) : (
              ""
            )}

            {location.pathname === `/settings/notifications/delivery` &&
            !appData.loginData.workflow_type ? (
              <Btn
                className={classes["header__btn"]}
                onClick={() => {
                  history.push("/settings/notifications/delivery/add");
                }}
                type={"button"}
                startIcon={<AddCircleRoundedIcon />}
                btnText={t("Header:Add Notification")}
              ></Btn>
            ) : (
              ""
            )}
            {(location.pathname === "/settings/notifications" ||
              location.pathname === `/settings/notifications/pickup`) &&
            !appData.loginData.workflow_type &&
            Permission["Create_Eventsettings"] ? (
              <Btn
                className={classes["header__btn"]}
                onClick={() => {
                  history.push("/settings/notifications/pickup/add");
                }}
                type={"button"}
                startIcon={<AddCircleRoundedIcon />}
                btnText={t("Header:Add Notification")}
              ></Btn>
            ) : (
              ""
            )}
            {location.pathname === `/settings/notifications/others` ? (
              <Btn
                className={classes["header__btn"]}
                onClick={() => {
                  history.push("/settings/notifications/others/add");
                }}
                type={"button"}
                startIcon={<AddCircleRoundedIcon />}
                btnText={t("Header:Add Notification")}
              ></Btn>
            ) : (
              ""
            )}
            {(location.pathname === `/settings/notifications/services` ||
              location.pathname === "/settings/notifications") &&
            loginData.workflow_type === 1 ? (
              <Btn
                className={classes["header__btn"]}
                onClick={() => {
                  history.push("/settings/notifications/services/add");
                }}
                type={"button"}
                startIcon={<AddCircleRoundedIcon />}
                btnText={t("Header:Add Notification")}
              ></Btn>
            ) : (
              ""
            )}
            {location.pathname === "/settings/earning-pricing" ||
            location.pathname === "/settings/enp/earning" ||
            (location.pathname === "/settings/enp/pricing" &&
              Permission &&
              Permission["Create_Enprules"]) ? (
              <>
                <Btn
                  className={classes["header__btn"]}
                  onClick={() => {
                    // let enp_type = useLocalStorage.getItem('enp_type');
                    {
                      enpType
                        ? history.push("/settings/pricing/add")
                        : history.push("/settings/earning/add");
                    }
                    // history.push("/settings/earningPricing/add")
                  }}
                  type={"button"}
                  startIcon={<AddCircleRoundedIcon />}
                  btnText={t("Header:Add New Rule")}
                ></Btn>
              </>
            ) : (
              ""
            )}
            {Permission &&
            Permission["Create_Formsettings"] &&
            location.pathname === "/settings/bookingform" ? (
              <Btn
                className={classes["header__btn"]}
                onClick={() => {
                  history.push("/settings/bookingform/add");
                }}
                type={"button"}
                startIcon={<AddCircleRoundedIcon />}
                btnText={t("Header:Add Form")}
              ></Btn>
            ) : (
              ""
            )}
            {isAdmin && location.pathname.includes("/settings/apikey") ? (
              <Btn
                onClick={() =>
                  triggerActionFromHeader("clickedonGenerateApiKey")
                }
                startIcon={<AddCircleRoundedIcon />}
                className={classes["header__btn"]}
                type={"button"}
                btnText={t("Header:New Key")}
              ></Btn>
            ) : (
              ""
            )}
            <div>
              {/* {Permission["Create_Jobs"] ? (
                <Btn
                  btnText="Create Job"
                  type="button"
                  color="primary"
                  size="medium"
                  onClick={() => {
                    triggerActionFromHeader("createJobHandle");
                  }}
                  className={classes.btn}
                ></Btn>
              ) : (
                <div></div>
              )} */}
            </div>

            {/* <Menu
              className="pointer"
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClickOpen}>Edit Profile</MenuItem>
              <MenuItem onClick={logoutFromDashboard}>Logout</MenuItem>
            </Menu> */}
            <Popper
              open={Boolean(anchorImportExport)}
              anchorEl={anchorImportExport}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="menu-list-grow">
                        {Permission["Create_Jobs"] ? (
                          <MenuItem
                            onClick={() => {
                              triggerActionFromHeader("openImportModal");
                              handleImportExportClose();
                            }}
                          >
                            {t("Header:Import Jobs")}
                          </MenuItem>
                        ) : (
                          ""
                        )}
                        {Permission && Permission["Read_Jobs"] ? (
                          <MenuItem
                            onClick={() => {
                              triggerActionFromHeader("openExportModal");
                              handleImportExportClose();
                            }}
                          >
                            {t("Header:Export Jobs")}
                          </MenuItem>
                        ) : (
                          ""
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <Popper
              open={Boolean(anchorImportExportCustomer)}
              anchorEl={anchorImportExportCustomer}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener
                      onClickAway={handleImportExportCustomerClose}
                    >
                      <MenuList id="menu-list-grow">
                        {Permission["Create_Customers"] ? (
                          <MenuItem
                            onClick={() => {
                              triggerActionFromHeader("openCustomerImport");
                              handleImportExportCustomerClose();
                            }}
                          >
                            {t("Header:Import")}
                          </MenuItem>
                        ) : (
                          ""
                        )}
                        {Permission && Permission["Read_Customers"] ? (
                          <MenuItem
                            onClick={() => {
                              triggerActionFromHeader("openCustomerExport");
                              handleImportExportCustomerClose();
                            }}
                          >
                            {t("Header:Export")}
                          </MenuItem>
                        ) : (
                          ""
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>

            <Popper
              open={Boolean(anchorImportExportAgent)}
              anchorEl={anchorImportExportAgent}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener
                      onClickAway={handleImportExportAgentClose}
                    >
                      <MenuList id="menu-list-grow">
                        {Permission["Create_Customers"] ? (
                          <MenuItem
                            onClick={() => {
                              triggerActionFromHeader("openAgentImport");
                              handleImportExportAgentClose();
                            }}
                          >
                            {t("Header:Import Agents")}
                          </MenuItem>
                        ) : (
                          ""
                        )}
                        {Permission && Permission["Read_Customers"] ? (
                          <MenuItem
                            onClick={() => {
                              triggerActionFromHeader("exportAgents");
                              handleImportExportAgentClose();
                            }}
                          >
                            {t("Header:Export Agents")}
                          </MenuItem>
                        ) : (
                          ""
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>

            <Popper
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="menu-list-grow">
                        <MenuItem onClick={handleClickOpen}>
                          {t("Header:Edit Profile")}
                        </MenuItem>
                        <MenuItem onClick={logoutFromDashboard}>
                          {t("Header:Logout")}
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <div
              onClick={handleClick}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {(loginData && loginData.user_image) || profileImage ? (
                <img
                  style={{
                    height: "30px",
                    width: "30px",
                    borderRadius: "25px",
                    border: "2px solid #fffff",
                  }}
                  className={classes["header__logout-icon"]}
                  src={profileImage || loginData.user_image}
                  alt="hi"
                  // onClick={handleClick}
                ></img>
              ) : (
                <AccountCircleIcon
                  className={classes["header__logout-icon"]}
                  style={{ color: themeColor.iconsColor }}
                  fontSize="large"
                ></AccountCircleIcon>
              )}
              {/* <span
                style={{
                  color: "#131523",
                  marginLeft: "10px",
                  maxWidth: "80px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "elipsis",
                }}
                className={classes["header__profile_name"]}
              >
                {loginData.name || ""}
              </span> */}
            </div>
            {/* <KeyboardArrowDownIcon
              onClick={handleClick}
              style={{ color: "#131523", cursor: "pointer" }}
            /> */}

            {/* <ExitToAppIcon
              onClick={logoutFromDashboard}
              className={classes["header__logout-icon"]}
              style={{ color: themeColor.iconsColor }}
            ></ExitToAppIcon> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Header;
